import { useCallback, useContext, useEffect, useState } from "react";
// material
import { Container, Grid, Typography } from "@mui/material";
// components
import Page from "../../components/Page";
//
import ControlUnitCard from "../../components/ControlUnits/ControlUnitCard";
import { GetVcDeviceApiClient } from "../../utils/HttpClient";
import { useMsal } from "@azure/msal-react";
import { ControlUnit } from "../../Models/ControlUnit";
import { StatusesContext } from "../../contexts/StatusesProvider";

// ----------------------------------------------------------------------

const ControlUnitsPage: React.FC = () => {
  const [controlUnits, setControlUnits] = useState<ReadonlyArray<ControlUnit>>(
    []
  );
  const { instance } = useMsal();

  const { statuses } = useContext(StatusesContext);

  const getControlUnits = useCallback(async () => {
    const axiosClient = await GetVcDeviceApiClient(instance);
    if (axiosClient) {
      axiosClient
        .get<ReadonlyArray<ControlUnit>>(`control-units`)
        .then((value) => {
          setControlUnits(value.data);
        });
    }
  }, [instance]);

  useEffect(() => {
    getControlUnits();
  }, [getControlUnits]);

  return (
    <Page title="Control Units">
      <Container>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Control Units
        </Typography>

        <Grid container spacing={3}>
          {controlUnits.map((controlUnit) => (
            <Grid key={controlUnit.id} item xs={12} sm={6} md={6}>
              <ControlUnitCard
                controlUnit={controlUnit}
                status={
                  statuses && controlUnit?.id
                    ? statuses.get(controlUnit.id)
                    : undefined
                }
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Page>
  );
};
export default ControlUnitsPage;
