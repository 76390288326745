import React, { useEffect, useCallback, useState } from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { Account } from "../Models/Account";
import { GetVcApiClient } from "../utils/HttpClient";
import { Contact } from "../Models/Contact";
import { User } from "../Models/User";

export type DataContextProps = {
  users: ReadonlyArray<User>;
  customers: ReadonlyArray<Account>;
  contacts: ReadonlyArray<Contact>;
};

export const DataContext = React.createContext<Partial<DataContextProps>>({
  users: [],
  customers: [],
  contacts: [],
});

export const DataProvider = ({ children }) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [users, setUsers] = useState<ReadonlyArray<User>>([]);
  const [customers, setCustomers] = useState<ReadonlyArray<Account>>([]);
  const [contacts, setContacts] = useState<ReadonlyArray<Contact>>([]);

  const [hasFetched, setHasFetched] = useState(false);

  const getData = useCallback(async () => {
    if (isAuthenticated) {
      const axiosClient = await GetVcApiClient(instance);
      if (!hasFetched && axiosClient) {
        setHasFetched(true);
        axiosClient.get<ReadonlyArray<User>>(`users`).then((value) => {
          setUsers(value.data);
        });
        axiosClient.get<ReadonlyArray<Account>>(`accounts`).then((value) => {
          setCustomers(value.data);
        });
        axiosClient.get<ReadonlyArray<Contact>>(`contacts`).then((value) => {
          setContacts(value.data);
        });
      }
    }
  }, [isAuthenticated, hasFetched, instance]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <DataContext.Provider
      value={{ users: users, customers: customers, contacts: contacts }}
    >
      {children}
    </DataContext.Provider>
  );
};
