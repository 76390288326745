import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Card,
  CardContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ControlUnitSelect from "./ControlUnits/ControlUnitSelect";
import { GetVcDeviceApiClient } from "../../utils/HttpClient";
import { ControlUnit } from "../../Models/ControlUnit";
import { useMsal } from "@azure/msal-react";
import { StatusesContext } from "../../contexts/StatusesProvider";
import { ControlUnitStatus } from "../../Models/ControlUnitStatus";
import { ControlUnitState } from "../../Models/ControlUnitState";

const ControlUnitSection: React.FC<{
  controlUnitId: string | null;
  onSetControlUnitId: (newId: string | null) => void;
}> = ({ controlUnitId, onSetControlUnitId }) => {
  const { instance } = useMsal();
  const { statuses } = useContext(StatusesContext);

  const [controlUnit, setControlUnit] = useState<ControlUnit | null>(null);
  const [status, setStatus] = useState<ControlUnitStatus | null>(null);

  const getControlUnit = useCallback(async () => {
    if (controlUnitId) {
      const axiosClient = await GetVcDeviceApiClient(instance);
      if (axiosClient) {
        axiosClient
          .get<ControlUnit>(`control-units/${controlUnitId}`)
          .then((value) => {
            setControlUnit(value.data);
          });
      }
    }
  }, [controlUnitId, instance]);

  useEffect(() => {
    getControlUnit();
  }, [getControlUnit]);

  useEffect(() => {
    if (statuses && controlUnit?.id) {
      let status = statuses.get(controlUnit?.id);
      if (status) {
        setStatus(status);
      }
    }
  }, [controlUnit?.id, statuses]);

  return (
    <Card>
      <CardContent>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Stack
              spacing={4}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography>Control unit: </Typography>
              <Typography>
                <i>
                  {controlUnit?.name
                    ? controlUnit?.name
                    : "No control unit selected"}
                </i>
              </Typography>
            </Stack>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              spacing={6}
              divider={<Divider orientation="horizontal" flexItem />}
            >
              <ControlUnitSelect
                selectedControlUnitId={controlUnitId}
                onSelectedControlUnit={(controlUnit) => {
                  onSetControlUnitId(controlUnit?.id ?? null);
                }}
              />
              {controlUnit && (
                <Stack spacing={2} sx={{ p: 3 }}>
                  <Typography variant="subtitle2" noWrap>
                    Name: {controlUnit.name ?? "Not configured"}
                  </Typography>
                  <Typography>
                    Location: {controlUnit.location ?? "Not configured"}
                  </Typography>
                  <Typography>
                    Device id: {controlUnit.deviceForeignId}
                  </Typography>
                  <Typography>
                    Status:{" "}
                    <span
                      color={
                        (status?.state === ControlUnitState.idle && "blue") ||
                        "green"
                      }
                    >
                      {ControlUnitState[status?.state ?? ControlUnitState.idle]}
                    </span>
                  </Typography>
                  <Typography>
                    Last seen at:{" "}
                    {status?.updatedAt
                      ? `${new Date(
                          status.updatedAt
                        ).toLocaleDateString()} - ${new Date(
                          status.updatedAt
                        ).toLocaleTimeString()}`
                      : "Not seen"}
                  </Typography>
                </Stack>
              )}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </CardContent>
    </Card>
  );
};

export default ControlUnitSection;
