import React from "react";
import { Stack, TextField } from "@mui/material";
import { ControlUnitState } from "../../../Models/ControlUnitState";
import { ControlUnit } from "../../../Models/ControlUnit";
import { ControlUnitStatus } from "../../../Models/ControlUnitStatus";

const ControlUnitDetails: React.FC<{
  controlUnit: ControlUnit;
  status: ControlUnitStatus;
}> = ({ controlUnit, status }) => {
  return (
    <Stack spacing={3}>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <TextField
          fullWidth
          label="Name"
          value={controlUnit.name ?? "Not configured"}
        />
        <TextField
          fullWidth
          label="Device id"
          value={controlUnit.deviceForeignId}
        />
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <TextField
          fullWidth
          label="Location"
          value={controlUnit.location ?? "Not configured"}
        />
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <TextField
          fullWidth
          label="Status"
          color={
            (status?.state === ControlUnitState.idle && "secondary") ||
            "primary"
          }
          value={ControlUnitState[status?.state ?? ControlUnitState.idle]}
        />
        <TextField
          fullWidth
          label="Last seen at"
          value={
            status?.updatedAt
              ? `${new Date(
                  status.updatedAt
                ).toLocaleDateString()} - ${new Date(
                  status.updatedAt
                ).toLocaleTimeString()}`
              : "Not seen"
          }
        />
      </Stack>
    </Stack>
  );
};
export default ControlUnitDetails;
