import { Link as RouterLink, Outlet } from "react-router-dom";
// material
import React from "react";
import { styled } from "@mui/material/styles";
import Logo from "../components/Logo";
import { useIsAuthenticated } from "@azure/msal-react";

// ----------------------------------------------------------------------

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  left: 0,
  lineHeight: 0,
  width: "100%",
  position: "absolute",
  padding: theme.spacing(3, 3, 0),
  [theme.breakpoints.up("sm")]: {
    padding: theme.spacing(5, 5, 0),
  },
}));

const LogoOnlyLayout: React.FC = (): JSX.Element => {
  const isAuthenticated = useIsAuthenticated();

  if (!isAuthenticated) {
  }
  return (
    <>
      <HeaderStyle>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
      </HeaderStyle>
      <Outlet />
    </>
  );
};
export default LogoOnlyLayout;
