import { filter } from "lodash";
import { Icon } from "@iconify/react";
import React, { useCallback, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

import plusFill from "@iconify/icons-eva/plus-fill";
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Snackbar,
  Alert,
  AlertColor,
  Checkbox,
} from "@mui/material";
// components
import Page from "../../components/Page";
import Scrollbar from "../../components/Scrollbar";
import SearchNotFound from "../../components/SearchNotFound";

import TestsListHead from "../../components/TestsRuns/TestsListHead";
import { GetVcApiClient } from "../../utils/HttpClient";
import { useMsal } from "@azure/msal-react";
import { Test } from "../../Models/Test";
import MoreMenu from "../../components/Common/MoreMenu";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "testSuiteName", label: "Test Suite Name", alignRight: false },
  { id: "numberOfTests", label: "Number of tests", alignRight: false },
  { id: "createdBy", label: "Created By", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
  { id: "", label: "", alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (test) => test.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const TestsPage: React.FC = () => {
  const { instance } = useMsal();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [selected, setSelected] = useState<Array<string>>([]);
  const [orderBy, setOrderBy] = useState("email");
  const [filterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>(
    undefined
  );
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    AlertColor | undefined
  >(undefined);

  const [tests, setTests] = useState<ReadonlyArray<Test>>([]);
  const navigate = useNavigate();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage(undefined);
    setSnackbarSeverity(undefined);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = tests.map((n) => n.id);
      setSelected(newSelecteds as Array<string>);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: Array<string> = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tests.length) : 0;

  const filteredUsers = applySortFilter(
    tests,
    getComparator(order, orderBy),
    filterName
  );

  const getTests = useCallback(async () => {
    const axiosClient = await GetVcApiClient(instance);
    if (axiosClient) {
      axiosClient.get<ReadonlyArray<Test>>(`tests`).then((value) => {
        setTests(value.data);
      });
    }
  }, [instance]);

  useEffect(() => {
    getTests();
  }, [getTests]);

  const isUserNotFound = filteredUsers.length === 0;
  return (
    <Page title="Tests">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Tests
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="new"
            startIcon={<Icon icon={plusFill} />}
          >
            New test
          </Button>
        </Stack>
        <Card>
          <Scrollbar sx={{}}>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TestsListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tests.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {tests
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((test) => {
                      const isItemSelected = selected.indexOf(test.id) !== -1;
                      return (
                        <TableRow
                          hover
                          key={test.id}
                          tabIndex={-1}
                          role="checkbox"
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                          onClick={() => navigate(test.id)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isItemSelected}
                              onChange={(event) => handleClick(event, test.id)}
                            />
                          </TableCell>
                          <TableCell align="left">{test.name}</TableCell>
                          <TableCell align="left">
                            {test.testRuns?.length ?? "No test runs"}
                          </TableCell>
                          <TableCell align="left">
                            {test.createdBy?.name}
                          </TableCell>
                          <TableCell align="left">
                            {new Date(test.createdAt).toLocaleDateString()} -{" "}
                            {new Date(test.createdAt).toLocaleTimeString()}
                          </TableCell>

                          <TableCell align="right">
                            <MoreMenu />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tests.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          <Typography variant="body1">{snackbarMessage}</Typography>
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default TestsPage;
