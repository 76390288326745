import React, { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import TestsListHead from "../TestsRuns/TestsListHead";
import SearchNotFound from "../SearchNotFound";
import { useNavigate } from "react-router-dom";
import { Run } from "../../Models/DeviceData/Run";
import { filter } from "lodash";
import { TestRun } from "../../Models/TestRun";

const TABLE_HEAD = [
  { id: "ranAt", label: "Ran At", alignRight: false },
  { id: "cycles", label: "Number of Cycles", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (test: TestRun) =>
        test.id.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const RunsListTable: React.FC<{ runs: ReadonlyArray<Run> }> = ({ runs }) => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [selected, setSelected] = useState<Array<string>>([]);
  const [orderBy, setOrderBy] = useState("email");
  const [filterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const navigate = useNavigate();

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = runs.map((n) => n.id);
      setSelected(newSelecteds as Array<string>);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - runs.length) : 0;

  const filteredRuns = applySortFilter(
    runs,
    getComparator(order, orderBy),
    filterName
  );
  const isRunNotFound = filteredRuns.length === 0;

  return (
    <>
      <TableContainer sx={{ minWidth: 800 }}>
        <Table>
          <TestsListHead
            order={order}
            orderBy={orderBy}
            headLabel={TABLE_HEAD}
            rowCount={runs.length}
            numSelected={selected.length}
            onRequestSort={handleRequestSort}
            onSelectAllClick={handleSelectAllClick}
            includeCheckbox={false}
          />
          <TableBody>
            {runs
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((run) => {
                const isItemSelected = selected.indexOf(run.id) !== -1;
                return (
                  <TableRow
                    hover
                    key={run.id}
                    tabIndex={-1}
                    role="checkbox"
                    selected={isItemSelected}
                    aria-checked={isItemSelected}
                    onClick={() => navigate(`/dashboard/runs/${run.id}`)}
                  >
                    <TableCell align="left">
                      {new Date(run.time).toLocaleDateString()} -{" "}
                      {new Date(run.time).toLocaleTimeString()}
                    </TableCell>
                    <TableCell align="left">
                      {run.atTestCycles?.length ?? "No cycles"}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 53 * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
          {isRunNotFound && (
            <TableBody>
              <TableRow>
                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                  <SearchNotFound searchQuery={filterName} />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={runs.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
export default RunsListTable;
