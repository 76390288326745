import React, { useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { GetVcApiClient } from "../../../utils/HttpClient";
import { Cycle } from "../../../Models/DeviceData/Cycle";
import { DataPoint } from "../../../Models/DeviceData/DataPoint";
import VacuumGraph from "../VacuumGraph";
import ValvePositionGraph from "../ValvePositionGraph";
import { Grid, Stack, TextField } from "@mui/material";
import VacuumMaterialGraph from "../VacuumMaterialGraph";
import PressureGraph from "../PressureGraph";

const CycleDetails: React.FC<{
  cycleId?: string;
  existingCycle?: Cycle;
  dataPoints?: ReadonlyArray<DataPoint>;
}> = ({ cycleId, existingCycle, dataPoints }) => {
  const { instance } = useMsal();
  const [cycle, setCycle] = useState<Cycle | null>(existingCycle ?? null);
  const [vaccumSensorValues, setVaccumSensorValues] = useState<
    Map<number, number>
  >(new Map<number, number>());
  const [vaccumMaterialSensorValues, setVaccumMaterialSensorValues] = useState<
    Map<number, number>
  >(new Map<number, number>());
  const [pressureSensorValues, setPressureSensorValues] = useState<
    Map<number, number>
  >(new Map<number, number>());
  const [valvePositions, setValvePositions] = useState<Map<number, number>>(
    new Map<number, number>()
  );

  const getCycles = useCallback(async () => {
    const axiosClient = await GetVcApiClient(instance);

    if (axiosClient && cycleId) {
      axiosClient.get<Cycle>(`device-data/cycles/${cycleId}`).then((value) => {
        setCycle(value.data);
      });
    }
  }, [instance, cycleId]);

  const getCycleDataPoints = useCallback(async () => {
    const axiosClient = await GetVcApiClient(instance);

    if (axiosClient && cycle && !dataPoints) {
      axiosClient
        .get<ReadonlyArray<DataPoint>>(
          `device-data/cycles/${cycle.id}/data-points`
        )
        .then((value) => {
          let vaccumSensorValues = new Map<number, number>();
          let vaccumMaterialSensorValues = new Map<number, number>();
          let pressureSensorValues = new Map<number, number>();
          let valvePosValues = new Map<number, number>();

          for (let dataPoint of value.data) {
            let ms =
              new Date(dataPoint.time).getTime() -
              new Date(cycle.time).getTime();
            vaccumSensorValues.set(ms, dataPoint.vacuum ?? 0);
            vaccumMaterialSensorValues.set(ms, dataPoint.vacuumM ?? 0);
            pressureSensorValues.set(ms, dataPoint.pressure ?? 0);
            valvePosValues.set(ms, dataPoint.valvePos ?? 0);
          }
          setVaccumSensorValues(vaccumSensorValues);
          setVaccumMaterialSensorValues(vaccumMaterialSensorValues);
          setPressureSensorValues(pressureSensorValues);
          setValvePositions(valvePosValues);
        });
    }
  }, [instance, cycle, dataPoints]);

  useEffect(() => {
    getCycles();
  }, [getCycles]);

  useEffect(() => {
    getCycleDataPoints();
  }, [getCycleDataPoints]);

  useEffect(() => {
    if (dataPoints && existingCycle) {
      let vaccumSensorValues = new Map<number, number>();
      let vaccumMaterialSensorValues = new Map<number, number>();
      let pressureSensorValues = new Map<number, number>();
      let valvePosValues = new Map<number, number>();

      for (let dataPoint of dataPoints) {
        let ms =
          new Date(dataPoint.time).getTime() -
          new Date(existingCycle.time).getTime();
        vaccumSensorValues.set(ms, dataPoint.vacuum ?? 0);
        vaccumMaterialSensorValues.set(ms, dataPoint.vacuumM ?? 0);
        pressureSensorValues.set(ms, dataPoint.pressure ?? 0);
        valvePosValues.set(ms, dataPoint.valvePos ?? 0);
      }
      setVaccumSensorValues(vaccumSensorValues);
      setVaccumMaterialSensorValues(vaccumMaterialSensorValues);
      setPressureSensorValues(pressureSensorValues);
      setValvePositions(valvePosValues);
    }
  }, [existingCycle, dataPoints]);

  useEffect(() => {
    if (existingCycle) {
      setCycle(existingCycle);
    }
  }, [existingCycle]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={3}>
        <Stack direction={"column"} spacing={2}>
          <TextField
            fullWidth
            label="Time started"
            value={`${new Date(
              cycle?.time ?? new Date()
            ).toLocaleDateString()} - ${new Date(
              cycle?.time ?? new Date()
            ).toLocaleTimeString()}`}
          />
          <TextField
            fullWidth
            type={"number"}
            label="Cycle"
            value={cycle?.cycle ?? ""}
          />
          <TextField
            fullWidth
            type={"number"}
            label="Time fill"
            value={cycle?.timeFill ?? ""}
          />
          <TextField
            fullWidth
            type={"number"}
            label="Pressure average"
            value={cycle?.pAvg ?? ""}
          />
          <TextField
            fullWidth
            type={"number"}
            label="Vacuum average"
            value={cycle?.vAvg ?? ""}
          />
          <TextField
            fullWidth
            type={"number"}
            label="Vacuum material average"
            value={cycle?.vmAvg ?? ""}
          />
          <TextField
            fullWidth
            type={"number"}
            label="Valve position"
            value={cycle?.valvePos ?? ""}
          />
        </Stack>
      </Grid>
      <Grid item xs={9}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            {cycle && <VacuumGraph vacuumSensorValues={vaccumSensorValues} />}
          </Grid>
          <Grid item xs={6}>
            {cycle && (
              <VacuumMaterialGraph
                vacuumMaterialSensorValues={vaccumMaterialSensorValues}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {cycle && (
              <PressureGraph pressureSensorValues={pressureSensorValues} />
            )}
          </Grid>
          <Grid item xs={6}>
            {cycle && <ValvePositionGraph valvePositions={valvePositions} />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default CycleDetails;
