import React, { useCallback, useEffect, useState } from "react";
import { Card, CardContent, CardHeader } from "@mui/material";
import RunsListTable from "./RunsListTable";
import { useMsal } from "@azure/msal-react";
import { GetVcApiClient } from "../../utils/HttpClient";
import { Run } from "../../Models/DeviceData/Run";

const RunsListCard: React.FC<{ controlUnitId: string }> = ({
  controlUnitId,
}) => {
  const { instance } = useMsal();
  const [runs, setRuns] = useState<ReadonlyArray<Run>>([]);

  const getRuns = useCallback(async () => {
    const axiosClient = await GetVcApiClient(instance);
    if (axiosClient) {
      axiosClient
        .get<ReadonlyArray<Run>>(`device-data/${controlUnitId}/runs`)
        .then((value) => {
          setRuns(value.data);
        });
    }
  }, [controlUnitId, instance]);

  useEffect(() => {
    getRuns();
  }, [getRuns]);

  return (
    <Card>
      <CardHeader title="Previous Runs" />
      <CardContent>
        <RunsListTable runs={runs} />
      </CardContent>
    </Card>
  );
};
export default RunsListCard;
