import React from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ControlUnitConfig } from "../../Models/ControlUnitConfig";

const ControlUnitConfigDialog: React.FC<{
  config: ControlUnitConfig | null;
  open: boolean;
  handleClose: () => void;
  maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
}> = ({ config, open, handleClose, maxWidth, children }) => {
  const theme = useTheme() as Theme;
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      maxWidth={maxWidth ?? "sm"}
      fullWidth
      fullScreen={fullScreen}
      open={open}
      scroll={"paper"}
      onClose={handleClose}
    >
      <DialogTitle>Config</DialogTitle>
      <DialogContent>
        <Stack direction={"row"}>
          <Stack direction={"column"}>
            {config?.blockage_interval !== undefined && (
              <Typography>blockage_interval: </Typography>
            )}
            {config?.vacuum_safety_mode !== undefined && (
              <Typography>vacuum_safety_mode: </Typography>
            )}
            {config?.valve_function !== undefined && (
              <Typography>valve_function: </Typography>
            )}
            {config?.pump_pressure !== undefined && (
              <Typography>pump_pressure: </Typography>
            )}
            {config?.shock_cycles !== undefined && (
              <Typography>shock_cycles: </Typography>
            )}
            {config?.discharge_highLow_diff_pct !== undefined && (
              <Typography>discharge_highLow_diff_pct: </Typography>
            )}
            {config?.pipe_emptying_time !== undefined && (
              <Typography>pipe_emptying_time: </Typography>
            )}
            {config?.unit && <Typography>unit: </Typography>}
            {config?.sensor_cleaning_time !== undefined && (
              <Typography>sensor_cleaning_time: </Typography>
            )}
            {config?.fluid_conveyor !== undefined && (
              <Typography>fluid_conveyor: </Typography>
            )}
            {config?.dischargeLow_factor !== undefined && (
              <Typography>dischargeLow_factor: </Typography>
            )}
            {config?.vacuum_safety_interval !== undefined && (
              <Typography>vacuum_safety_interval: </Typography>
            )}
            {config?.density && <Typography>density: </Typography>}
            {config?.sensor_cleaning_interval !== undefined && (
              <Typography>sensor_cleaning_interval: </Typography>
            )}
            {config?.shock_release_time !== undefined && (
              <Typography>shock_release_time: </Typography>
            )}
            {config?.epoll && <Typography>epoll: </Typography>}
            {config?.time_delay && <Typography>time_delay: </Typography>}
            {config?.change_step_evaluation !== undefined && (
              <Typography>change_step_evaluation: </Typography>
            )}
            {config?.exit_on_pump !== undefined && (
              <Typography>exit_on_pump: </Typography>
            )}
            {config?.valve_pos_max !== undefined && (
              <Typography>valve_pos_max: </Typography>
            )}
            {config?.average_offset !== undefined && (
              <Typography>average_offset: </Typography>
            )}
            {config?.level_detector_recv !== undefined && (
              <Typography>level_detector_recv: </Typography>
            )}
            {config?.evaluation_count !== undefined && (
              <Typography>evaluation_count: </Typography>
            )}
            {config?.start_valve_pos !== undefined && (
              <Typography>start_valve_pos: </Typography>
            )}
            {config?.status_poll_time !== undefined && (
              <Typography>status_poll_time: </Typography>
            )}
            {config?.mode && <Typography>mode: </Typography>}
            {config?.change_step_calibration !== undefined && (
              <Typography>change_step_calibration: </Typography>
            )}
            {config?.pump_control !== undefined && (
              <Typography>pump_control: </Typography>
            )}
            {config?.opening_direction !== undefined && (
              <Typography>opening_direction: </Typography>
            )}
            {config?.level_detector_delay_charge !== undefined && (
              <Typography>level_detector_delay_charge: </Typography>
            )}
            {config?.charge_time !== undefined && (
              <Typography>charge_time: </Typography>
            )}
            {config?.sensor_cleaning !== undefined && (
              <Typography>sensor_cleaning: </Typography>
            )}
            {config?.current_material !== undefined && (
              <Typography>current_material: </Typography>
            )}
            {config?.pump_start_delay !== undefined && (
              <Typography>pump_start_delay: </Typography>
            )}
            {config?.func && <Typography>func: </Typography>}
            {config?.test_name && <Typography>test_name: </Typography>}
            {config?.shockLoad_time !== undefined && (
              <Typography>shockLoad_time: </Typography>
            )}
            {config?.vacuum_safety !== undefined && (
              <Typography>vacuum_safety: </Typography>
            )}
            {config?.discharge_min_remaining_time !== undefined && (
              <Typography>discharge_min_remaining_time: </Typography>
            )}
            {config?.level_detector_delay_discharge !== undefined && (
              <Typography>level_detector_delay_discharge: </Typography>
            )}
            {config?.name && <Typography>name: </Typography>}
            {config?.language && <Typography>language: </Typography>}
            {config?.conveyor_volume !== undefined && (
              <Typography>conveyor_volume: </Typography>
            )}
            {config?.level_detector_delay_external !== undefined && (
              <Typography>level_detector_delay_external: </Typography>
            )}
            {config?.fluid_conveyor_offset !== undefined && (
              <Typography>fluid_conveyor_offset: </Typography>
            )}
            {config?.time_fill_improvement !== undefined && (
              <Typography>time_fill_improvement: </Typography>
            )}
            {config?.calibration_charge_time !== undefined && (
              <Typography>calibration_charge_time: </Typography>
            )}
            {config?.vibrator && <Typography>vibrator: </Typography>}
            {config?.valve_open_delay !== undefined && (
              <Typography>valve_open_delay: </Typography>
            )}
            {config?.level_detector_feed !== undefined && (
              <Typography>level_detector_feed: </Typography>
            )}
            {config?.poll_tick && <Typography>poll_tick: </Typography>}
            {config?.cycles && <Typography>cycles: </Typography>}
            {config?.discharge_time !== undefined && (
              <Typography>discharge_time: </Typography>
            )}
            {config?.accessory_vibrator !== undefined && (
              <Typography>accessory_vibrator: </Typography>
            )}
            {config?.system_filter_size !== undefined && (
              <Typography>system_filter_size: </Typography>
            )}
            {config?.shock_period !== undefined && (
              <Typography>shock_period: </Typography>
            )}
            {config?.discharge_high_low_diff_pct !== undefined && (
              <Typography>discharge_high_low_diff_pct: </Typography>
            )}
            {config?.accessory_pleu !== undefined && (
              <Typography>accessory_pleu: </Typography>
            )}
            {config?.discharge_low_factor !== undefined && (
              <Typography>discharge_low_factor: </Typography>
            )}
            {config?.filter_mon_max_cycle !== undefined && (
              <Typography>filter_mon_max_cycle: </Typography>
            )}
            {config?.discharge_opening_type !== undefined && (
              <Typography>discharge_opening_type: </Typography>
            )}
            {config?.vacuum_diff_level !== undefined && (
              <Typography>vacuum_diff_level: </Typography>
            )}
            {config?.filter_mon_function !== undefined && (
              <Typography>filter_mon_function: </Typography>
            )}
            {config?.filter_id && <Typography>filter_id: </Typography>}
            {config?.discharge_opt !== undefined && (
              <Typography>discharge_opt: </Typography>
            )}
            {config?.level_detector_1 !== undefined && (
              <Typography>level_detector_1: </Typography>
            )}
            {config?.shock_tanks !== undefined && (
              <Typography>shock_tanks: </Typography>
            )}
            {config?.filter_type !== undefined && (
              <Typography>filter_type: </Typography>
            )}
            {config?.shock_load_time !== undefined && (
              <Typography>shock_load_time: </Typography>
            )}
            {config?.pump_technology !== undefined && (
              <Typography>pump_technology: </Typography>
            )}
            {config?.level_detector_2 !== undefined && (
              <Typography>level_detector_2: </Typography>
            )}
            {config?.filter_size !== undefined && (
              <Typography>filter_size: </Typography>
            )}
            {config?.filter_chosen !== undefined && (
              <Typography>filter_chosen: </Typography>
            )}
            {config?.pump_size !== undefined && (
              <Typography>pump_size: </Typography>
            )}
          </Stack>
          <Stack direction={"column"}>
            {config?.blockage_interval !== undefined && (
              <Typography>{config?.blockage_interval}</Typography>
            )}
            {config?.vacuum_safety_mode !== undefined && (
              <Typography>{config?.vacuum_safety_mode}</Typography>
            )}
            {config?.valve_function !== undefined && (
              <Typography>{config?.valve_function}</Typography>
            )}
            {config?.pump_pressure !== undefined && (
              <Typography>{config?.pump_pressure}</Typography>
            )}
            {config?.shock_cycles !== undefined && (
              <Typography>{config?.shock_cycles}</Typography>
            )}
            {config?.discharge_highLow_diff_pct !== undefined && (
              <Typography>{config?.discharge_highLow_diff_pct}</Typography>
            )}
            {config?.pipe_emptying_time !== undefined && (
              <Typography>{config?.pipe_emptying_time}</Typography>
            )}
            {config?.unit && <Typography>{config?.unit}</Typography>}
            {config?.sensor_cleaning_time !== undefined && (
              <Typography>{config?.sensor_cleaning_time}</Typography>
            )}
            {config?.fluid_conveyor !== undefined && (
              <Typography>{config?.fluid_conveyor}</Typography>
            )}
            {config?.dischargeLow_factor !== undefined && (
              <Typography>{config?.dischargeLow_factor}</Typography>
            )}
            {config?.vacuum_safety_interval !== undefined && (
              <Typography>{config?.vacuum_safety_interval}</Typography>
            )}
            {config?.density !== undefined && (
              <Typography>{config?.density}</Typography>
            )}
            {config?.sensor_cleaning_interval !== undefined && (
              <Typography>{config?.sensor_cleaning_interval}</Typography>
            )}
            {config?.shock_release_time !== undefined && (
              <Typography>{config?.shock_release_time}</Typography>
            )}
            {config?.epoll !== undefined && (
              <Typography>{config?.epoll ? "True" : "False"}</Typography>
            )}
            {config?.time_delay !== undefined && (
              <Typography>{config?.time_delay}</Typography>
            )}
            {config?.change_step_evaluation !== undefined && (
              <Typography>{config?.change_step_evaluation}</Typography>
            )}
            {config?.exit_on_pump !== undefined && (
              <Typography>{config?.exit_on_pump ? "True" : "False"}</Typography>
            )}
            {config?.valve_pos_max !== undefined && (
              <Typography>{config?.valve_pos_max}</Typography>
            )}
            {config?.average_offset !== undefined && (
              <Typography>{config?.average_offset}</Typography>
            )}
            {config?.level_detector_recv !== undefined && (
              <Typography>{config?.level_detector_recv}</Typography>
            )}
            {config?.evaluation_count !== undefined && (
              <Typography>{config?.evaluation_count}</Typography>
            )}
            {config?.start_valve_pos !== undefined && (
              <Typography>{config?.start_valve_pos}</Typography>
            )}
            {config?.status_poll_time !== undefined && (
              <Typography>{config?.status_poll_time}</Typography>
            )}
            {config?.mode && <Typography>{config?.mode}</Typography>}
            {config?.change_step_calibration !== undefined && (
              <Typography>{config?.change_step_calibration}</Typography>
            )}
            {config?.pump_control !== undefined && (
              <Typography>{config?.pump_control}</Typography>
            )}
            {config?.opening_direction !== undefined && (
              <Typography>{config?.opening_direction}</Typography>
            )}
            {config?.level_detector_delay_charge !== undefined && (
              <Typography>{config?.level_detector_delay_charge}</Typography>
            )}
            {config?.charge_time !== undefined && (
              <Typography>{config?.charge_time}</Typography>
            )}
            {config?.sensor_cleaning !== undefined && (
              <Typography>{config?.sensor_cleaning}</Typography>
            )}
            {config?.current_material !== undefined && (
              <Typography>
                {config?.current_material ?? "No material"}
              </Typography>
            )}
            {config?.pump_start_delay !== undefined && (
              <Typography>{config?.pump_start_delay}</Typography>
            )}
            {config?.func && <Typography>{config?.func}</Typography>}
            {config?.test_name !== undefined && (
              <Typography>{config?.test_name}</Typography>
            )}
            {config?.shockLoad_time !== undefined && (
              <Typography>{config?.shockLoad_time}</Typography>
            )}
            {config?.vacuum_safety !== undefined && (
              <Typography>{config?.vacuum_safety}</Typography>
            )}
            {config?.discharge_min_remaining_time !== undefined && (
              <Typography>{config?.discharge_min_remaining_time}</Typography>
            )}
            {config?.level_detector_delay_discharge !== undefined && (
              <Typography>{config?.level_detector_delay_discharge}</Typography>
            )}
            {config?.name && <Typography>{config?.name}</Typography>}
            {config?.language !== undefined && (
              <Typography>{config?.language}</Typography>
            )}
            {config?.conveyor_volume !== undefined && (
              <Typography>{config?.conveyor_volume}</Typography>
            )}
            {config?.level_detector_delay_external !== undefined && (
              <Typography>{config?.level_detector_delay_external}</Typography>
            )}
            {config?.fluid_conveyor_offset !== undefined && (
              <Typography>{config?.fluid_conveyor_offset}</Typography>
            )}
            {config?.time_fill_improvement !== undefined && (
              <Typography>{config?.time_fill_improvement}</Typography>
            )}
            {config?.calibration_charge_time !== undefined && (
              <Typography>{config?.calibration_charge_time}</Typography>
            )}
            {config?.vibrator !== undefined && (
              <Typography>{config?.vibrator}</Typography>
            )}
            {config?.valve_open_delay !== undefined && (
              <Typography>{config?.valve_open_delay}</Typography>
            )}
            {config?.level_detector_feed !== undefined && (
              <Typography>{config?.level_detector_feed}</Typography>
            )}
            {config?.poll_tick !== undefined && (
              <Typography>{config?.poll_tick}</Typography>
            )}
            {config?.cycles && <Typography>{config?.cycles}</Typography>}
            {config?.discharge_time !== undefined && (
              <Typography>{config?.discharge_time}</Typography>
            )}
            {config?.accessory_vibrator !== undefined && (
              <Typography>{config?.accessory_vibrator}</Typography>
            )}
            {config?.system_filter_size !== undefined && (
              <Typography>{config?.system_filter_size}</Typography>
            )}
            {config?.shock_period !== undefined && (
              <Typography>{config?.shock_period}</Typography>
            )}
            {config?.discharge_high_low_diff_pct !== undefined && (
              <Typography>{config?.discharge_high_low_diff_pct}</Typography>
            )}
            {config?.accessory_pleu !== undefined && (
              <Typography>{config?.accessory_pleu}</Typography>
            )}
            {config?.discharge_low_factor !== undefined && (
              <Typography>{config?.discharge_low_factor}</Typography>
            )}
            {config?.filter_mon_max_cycle !== undefined && (
              <Typography>{config?.filter_mon_max_cycle}</Typography>
            )}
            {config?.discharge_opening_type !== undefined && (
              <Typography>{config?.discharge_opening_type}</Typography>
            )}
            {config?.vacuum_diff_level !== undefined && (
              <Typography>{config?.vacuum_diff_level}</Typography>
            )}
            {config?.filter_mon_function !== undefined && (
              <Typography>{config?.filter_mon_function}</Typography>
            )}
            {config?.filter_id !== undefined && (
              <Typography>{config?.filter_id}</Typography>
            )}
            {config?.discharge_opt !== undefined && (
              <Typography>{config?.discharge_opt}</Typography>
            )}
            {config?.level_detector_1 !== undefined && (
              <Typography>{config?.level_detector_1}</Typography>
            )}
            {config?.shock_tanks !== undefined && (
              <Typography>{config?.shock_tanks}</Typography>
            )}
            {config?.filter_type !== undefined && (
              <Typography>{config?.filter_type}</Typography>
            )}
            {config?.shock_load_time !== undefined && (
              <Typography>{config?.shock_load_time}</Typography>
            )}
            {config?.pump_technology !== undefined && (
              <Typography>{config?.pump_technology}</Typography>
            )}
            {config?.level_detector_2 !== undefined && (
              <Typography>{config?.level_detector_2}</Typography>
            )}
            {config?.filter_size !== undefined && (
              <Typography>{config?.filter_size}</Typography>
            )}
            {config?.filter_chosen !== undefined && (
              <Typography>{config?.filter_chosen}</Typography>
            )}
            {config?.pump_size !== undefined && (
              <Typography>{config?.pump_size}</Typography>
            )}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
export default ControlUnitConfigDialog;
