export interface ApiConfiguration {
  apiUrl: string;
  deviceApiUrl: string;
  graphEndpoint: string;
}
// Config object to be passed to Msal on creation
export const apiConfig: ApiConfiguration = {
  apiUrl: process.env.REACT_APP_VC_API_URL
    ? process.env.REACT_APP_VC_API_URL
    : "https://localhost:5000",
  deviceApiUrl: process.env.REACT_APP_VC_DEVICE_API_URL
    ? process.env.REACT_APP_VC_DEVICE_API_URL
    : "https://localhost:5001",
  graphEndpoint: "https://graph.microsoft.com/v1.0",
};
