import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Modal,
  Stack,
  Typography,
  Grid,
  TextField,
} from "@mui/material";
import { Icon } from "@iconify/react";
import arrowBackFill from "@iconify/icons-eva/arrow-back-fill";
import Page from "../../components/Page";
import { TestRun } from "../../Models/TestRun";
import { GetVcApiClient, GetVcDeviceApiClient } from "../../utils/HttpClient";
import { useMsal } from "@azure/msal-react";
import { StatusesContext } from "../../contexts/StatusesProvider";
import { ControlUnitStatus } from "../../Models/ControlUnitStatus";
import TestRunDetails from "../../components/TestsRuns/TestRunDetails";
import ControlUnitDetails from "../../components/TestsRuns/ControlUnits/ControlUnitDetails";
import playCircleFill from "@iconify/icons-eva/play-circle-fill";
import { ControlUnitState } from "../../Models/ControlUnitState";
import stopCircleFill from "@iconify/icons-eva/stop-circle-fill";
import { LoadingButton } from "@mui/lab";
import { Cycle } from "../../Models/DeviceData/Cycle";
import { TestRunTestResult } from "../../Models/TestRunTestResult";
import RunResultDetails from "../../components/Runs/RunResultDetails";
import { Run } from "../../Models/DeviceData/Run";
import CycleDetails from "../../components/TestsRuns/TestResults/CycleDetails";
import { DeviceDataContext } from "../../contexts/DeviceDataProvider";
import RunsSelect from "../../components/Runs/RunsSelect";
import AverageGraph, {
  AverageRecordType,
} from "../../components/TestsRuns/AverageGraph";
import { ControlUnitParamsDto } from "../../Models/Dto/StartCommandDto";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

interface StartParams {
  isStarting: boolean;
  started: boolean;
  isWaitingForRunStart: boolean;
  startFailed: boolean;
  failureReason: string;
  startTime: Date;
}

const TestRunPage: React.FC = () => {
  const { instance } = useMsal();
  const { statuses } = useContext(StatusesContext);
  const { runStarts, latestControlUnitCycles } = useContext(DeviceDataContext);
  const { id } = useParams();

  const [cycles, setCycles] = useState<Cycle[]>([]);
  const [cycleIndex, setCycleIndex] = useState<number>(0);
  const [startCycles, setStartCycles] = useState<string | undefined>(undefined);
  const [avg, setAvg] = useState<AverageRecordType[]>();
  const [testRun, setTestRun] = useState<TestRun | null>(null);
  const [run, setRun] = useState<Run | null>(null);
  const [status, setStatus] = useState<ControlUnitStatus | null>(null);
  const [openStartConfirmationModal, setOpenStartConfirmationModal] =
    useState(false);
  const [startParams, setStartParams] = useState<StartParams>({
    isStarting: false,
    started: false,
    startFailed: false,
    isWaitingForRunStart: false,
    failureReason: "",
    startTime: new Date(),
  });
  const navigate = useNavigate();

  const getTestRun = useCallback(async () => {
    if (id) {
      const axiosClient = await GetVcApiClient(instance);
      if (axiosClient) {
        axiosClient.get<TestRun>(`test-runs/${id}`).then((value) => {
          setTestRun(value.data);
          if (value.data?.testRunResult?.run) {
            axiosClient
              .get<Run>(`device-data/runs/${value.data.testRunResult.run}`)
              .then((value) => {
                let average = value.data.atTestCycles?.map(
                  (item): AverageRecordType => {
                    return {
                      id: item.id,
                      recordTime: new Date(item.time),
                      p: item.pAvg,
                      v: item.vAvg,
                      vm: item.vmAvg,
                      pos: item.valvePos,
                      diff:
                        item?.vAvg && item.vmAvg
                          ? (item.vAvg - item.vmAvg).toFixed(2)
                          : null,
                    };
                  }
                );
                setAvg(average);
                setRun(value.data);
                let orderedCycles = [...(value.data.atTestCycles ?? [])].sort(
                  (a, b) => (b.cycle ?? 0) - (a.cycle ?? 0)
                );
                setCycles(orderedCycles.reverse());
                if (orderedCycles.length > 0) {
                  setCycleIndex(orderedCycles.length - 1);
                }
              })
              .catch((reason) => console.log(reason));
          }
        });
      }
    }
  }, [id, instance]);

  useEffect(() => {
    getTestRun();
  }, [getTestRun]);

  useEffect(() => {
    if (statuses && testRun?.controlUnit?.id) {
      let newStatus = statuses.get(testRun?.controlUnit?.id);
      if (newStatus) {
        if (
          status?.state !== newStatus.state ||
          (status?.updatedAt !== undefined &&
            newStatus.updatedAt !== undefined &&
            status?.updatedAt < newStatus.updatedAt)
        ) {
          setStatus(newStatus);
        }
        if (startParams.started && status?.state === ControlUnitState.idle) {
          setStartParams({ ...startParams, started: false });
          console.log("set to idle");
          return;
        } else if (
          !startParams.started &&
          status?.state !== ControlUnitState.idle &&
          status?.state
        ) {
          setStartParams({ ...startParams, started: true });
          console.log("set to started");
          console.log(startParams.started);
          console.log(status?.state);
        }

        let now = new Date();
        let timeout = new Date((startParams.startTime.getTime() ?? 0) + 15000);
        if (
          startParams.isStarting &&
          now < timeout &&
          newStatus.state !== ControlUnitState.idle
        ) {
          setStartParams({ ...startParams, started: true, isStarting: false });
          console.log("start confirmed");
        } else if (startParams.isStarting && now >= timeout) {
          setStartParams({ ...startParams, isStarting: false });
          console.log("Start timed out");
        }
      }
    }
  }, [
    testRun?.controlUnit?.id,
    statuses,
    startParams,
    status?.state,
    status?.updatedAt,
  ]);

  const startControlUnit = async (data: ControlUnitParamsDto) => {
    const axiosClient = await GetVcDeviceApiClient(instance);
    if (axiosClient) {
      setStartParams({
        ...startParams,
        isStarting: true,
        isWaitingForRunStart: testRun?.testRunResult?.run === undefined,
        failureReason: "",
        startFailed: false,
        startTime: new Date(),
      });
      axiosClient
        .post(
          `control-units/${testRun?.controlUnit?.deviceForeignId}/start`,
          data
        )
        .then((value) => {
          setOpenStartConfirmationModal(false);
        })
        .catch((reason) => {
          setStartParams({
            ...startParams,
            isStarting: false,
            startFailed: true,
            failureReason: reason.response.data.data,
          });
        });
    }
  };

  const stopControlUnit = async () => {
    const axiosClient = await GetVcDeviceApiClient(instance);
    if (axiosClient) {
      axiosClient
        .post(`control-units/${testRun?.controlUnit?.deviceForeignId}/stop`)
        .then((value) => {
          console.log(value.data);
        });
    }
  };

  const createTestRunResult = useCallback(
    async (testId: string) => {
      const axiosClient = await GetVcApiClient(instance);
      if (axiosClient) {
        axiosClient
          .post<TestRunTestResult>(`test-runs/${id}/test-results`, { testId })
          .then((value) => {
            setTestRun({ ...testRun, testRunResult: value.data } as TestRun);
            getTestRun();
          });
      }
    },
    [instance, id, testRun, getTestRun]
  );

  useEffect(() => {
    let runStart = runStarts?.get(testRun?.controlUnit?.deviceForeignId ?? "");

    if (startParams.isWaitingForRunStart && runStart) {
      createTestRunResult(runStart.testId);
      setStartParams({ ...startParams, isWaitingForRunStart: false });
      console.log("Created new test run result");
    }
  }, [
    runStarts,
    createTestRunResult,
    startParams,
    testRun?.controlUnit?.deviceForeignId,
  ]);

  useEffect(() => {
    if (testRun?.testRunResult?.run && testRun.controlUnit?.deviceForeignId) {
      let newCycle = latestControlUnitCycles?.get(
        testRun.controlUnit?.deviceForeignId
      );
      if (newCycle && newCycle.atTestRunId === testRun.testRunResult.run) {
        const updatedCycles = [...cycles];
        updatedCycles.unshift(newCycle);
        setCycles(updatedCycles);
        console.log("Set cycle");
      }
    }
  }, [
    latestControlUnitCycles,
    testRun?.controlUnit?.deviceForeignId,
    testRun?.testRunResult?.run,
    cycles,
  ]);

  const currentCycle = cycles.at(cycleIndex);

  return (
    <Page title="Test run">
      {!testRun && <>No test run found</>}
      {testRun && (
        <Container>
          <Stack direction="row" spacing={2} mb={5}>
            <IconButton onClick={() => navigate(-1)}>
              <Icon icon={arrowBackFill} />
            </IconButton>
            <Typography variant="h4" gutterBottom>
              Test run
            </Typography>
          </Stack>
          <Stack direction="column" spacing={2}>
            <Card>
              <CardHeader title="Test results" />
              <CardContent>
                {run && (
                  <Stack spacing={3}>
                    {!run.atTestRunResult && (
                      <Typography>No results of run</Typography>
                    )}
                    {run.atTestRunResult && (
                      <RunResultDetails results={run.atTestRunResult} />
                    )}
                    <Typography>
                      Cycle {cycleIndex + 1} of {cycles.length}
                    </Typography>
                    <Stack flexDirection="row" alignItems="center">
                      <Grid
                        width={50}
                        m={2}
                        onClick={() => {
                          if (cycleIndex > 0) {
                            setCycleIndex(cycleIndex - 1);
                          }
                        }}
                      >
                        {cycleIndex > 0 && (
                          <Icon
                            icon="material-symbols:arrow-back-ios"
                            width={40}
                          />
                        )}
                      </Grid>
                      <CycleDetails cycleId={currentCycle?.id} />{" "}
                      <Grid
                        width={50}
                        m={2}
                        onClick={() => {
                          if (cycleIndex !== cycles.length - 1) {
                            setCycleIndex((ix) => ix + 1);
                          }
                        }}
                      >
                        {cycleIndex < cycles.length - 1 && (
                          <Icon
                            icon="material-symbols:arrow-forward-ios"
                            width={40}
                          />
                        )}
                      </Grid>
                    </Stack>
                  </Stack>
                )}
                {!run && testRun.controlUnit && (
                  <Stack direction="column" spacing={2}>
                    <RunsSelect
                      deviceId={testRun.controlUnit.deviceForeignId}
                      onSelectedRun={(newRunId) => {
                        createTestRunResult(newRunId);
                      }}
                    />
                    {!startParams.started && (
                      <Typography>Start new run for test</Typography>
                    )}
                    {currentCycle && (
                      <CycleDetails
                        existingCycle={currentCycle}
                        // dataPoints={dataPoints.get(currentCycle.id)}
                      />
                    )}
                    <Stack spacing={3} direction={"row"}>
                      <Button
                        color="success"
                        variant="contained"
                        startIcon={<Icon icon={playCircleFill} />}
                        onClick={() => setOpenStartConfirmationModal(true)}
                        disabled={
                          (startParams.isStarting ||
                            startParams.started ||
                            status?.state !== ControlUnitState.idle) &&
                          status?.updatedAt !== null
                        }
                      >
                        {startParams.isStarting
                          ? "Starting"
                          : startParams.started
                          ? "Started"
                          : "Start"}
                      </Button>
                      <Button
                        color="error"
                        variant="contained"
                        startIcon={<Icon icon={stopCircleFill} />}
                        onClick={() => stopControlUnit()}
                        disabled={
                          !(startParams.isStarting || startParams.started)
                        }
                      >
                        Stop
                      </Button>
                    </Stack>
                  </Stack>
                )}
                {!testRun.controlUnit && (
                  <Typography>No control unit selected</Typography>
                )}
              </CardContent>
            </Card>
            {avg && (
              <Card>
                <CardHeader title="Avrage details" />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <AverageGraph
                        chartType="p"
                        data={avg}
                        name="Average Pressure"
                        xAxisTitle="Cycles"
                        yAxisTitle="Pressure"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AverageGraph
                        chartType="v"
                        data={avg}
                        name="Average Vacuum"
                        xAxisTitle="Cycles"
                        yAxisTitle="Vacuum"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AverageGraph
                        chartType="vm"
                        data={avg}
                        name="Average Vacuum Material"
                        xAxisTitle="Cycles"
                        yAxisTitle="Vacuum material"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AverageGraph
                        chartType="diff"
                        data={avg}
                        name="Differential Vacuum"
                        xAxisTitle="Cycles"
                        yAxisTitle="differential vacuum"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AverageGraph
                        chartType="pos"
                        data={avg}
                        name="Historical Valve Position"
                        xAxisTitle="Cycles"
                        yAxisTitle="Valve Position"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
            <Card>
              <CardHeader title="Run details" />
              <CardContent>
                <TestRunDetails testRun={testRun} />
              </CardContent>
            </Card>
            <Card>
              <CardHeader title="Control unit" />
              <CardContent>
                {testRun.controlUnit && status && (
                  <ControlUnitDetails
                    controlUnit={testRun.controlUnit}
                    status={status}
                  />
                )}
                {!testRun.controlUnit && (
                  <Typography>No control unit selected</Typography>
                )}
              </CardContent>
            </Card>
          </Stack>
          <Modal
            open={openStartConfirmationModal}
            onClose={() => setOpenStartConfirmationModal(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Stack direction={"column"} spacing={4}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  Please confirm that the device is safe to run before starting!
                </Typography>
                <TextField
                  value={startCycles}
                  type={"number"}
                  label="Number of Cycles"
                  onChange={(e) => {
                    const target = e.target as HTMLInputElement;
                    setStartCycles(target.value);
                  }}
                />
                <LoadingButton
                  color="success"
                  variant="contained"
                  startIcon={<Icon icon={playCircleFill} />}
                  onClick={() => {
                    const data: ControlUnitParamsDto = {};
                    if (!isNaN(Number(startCycles))) {
                      data.cycles = Number(startCycles);
                    }
                    startControlUnit(data);
                  }}
                  loading={startParams.isStarting}
                  disabled={startParams.isStarting || startParams.started}
                >
                  {startParams.isStarting ? "Starting" : "Start run"}
                </LoadingButton>
                {startParams.startFailed && (
                  <Alert severity="error">
                    <AlertTitle>
                      Start command failed because of reason:{" "}
                      {startParams.failureReason}
                    </AlertTitle>
                  </Alert>
                )}
              </Stack>
            </Box>
          </Modal>
        </Container>
      )}
    </Page>
  );
};

export default TestRunPage;
