import React from "react";
import { RunResult } from "../../Models/DeviceData/RunResult";
import { Stack, TextField } from "@mui/material";

const RunResultDetails: React.FC<{ results?: RunResult }> = ({ results }) => {
  return results ? (
    <Stack spacing={3}>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <TextField
          fullWidth
          type={"number"}
          label="Best valve position"
          value={results.valvePosBest ?? ""}
        />
        <TextField
          fullWidth
          label="Best fill time"
          value={results.timeFillBest ?? ""}
        />
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <TextField
          fullWidth
          label="Best direction"
          value={results.directionBest ?? ""}
        />
        <TextField
          fullWidth
          type={"number"}
          label="Total time fill evaluation"
          value={results.timeFillEvaluationTotal ?? ""}
        />
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <TextField
          fullWidth
          type={"number"}
          label="Time fill best cycle"
          value={results.timeFillBestCycle ?? ""}
        />
        <TextField
          fullWidth
          type={"number"}
          label="Best update count"
          value={results.bestUpdateCount ?? ""}
        />
      </Stack>
    </Stack>
  ) : null;
};
export default RunResultDetails;
