import * as Yup from "yup";
import React, { useCallback, useEffect, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
// material
import {
  Alert,
  AlertTitle,
  Card,
  CardContent,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useMsal } from "@azure/msal-react";
import { GetVcDeviceApiClient } from "../../utils/HttpClient";
import { useNavigate, useParams } from "react-router-dom";
import { UpdateControlUnitDto } from "../../Models/Dto/UpdateControlUnitDto";
import { ControlUnit } from "../../Models/ControlUnit";
import { Icon } from "@iconify/react";
import arrowBackFill from "@iconify/icons-eva/arrow-back-fill";

// ----------------------------------------------------------------------

interface FormModel {
  name: string;
  location: string;
}

const toFormModel = (model: UpdateControlUnitDto): FormModel => {
  return {
    name: model.name ?? "",
    location: model.location ?? "",
  };
};

const toDto = (model: FormModel): UpdateControlUnitDto => {
  return {
    name: model.name === "" ? undefined : model.name,
    location: model.location === "" ? undefined : model.location,
  };
};

const EditControlUnitPage: React.FC = () => {
  const { id } = useParams();
  const { instance } = useMsal();
  const [controlUnit, setControlUnit] = useState<ControlUnit | null>(null);
  const navigate = useNavigate();

  const [submitError, setSubmitError] = useState(false);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    location: Yup.string().required("Location is required"),
  });

  const formik = useFormik({
    initialValues: toFormModel({}),
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      const axiosClient = await GetVcDeviceApiClient(instance);
      if (axiosClient) {
        axiosClient
          .put<string>(`control-units/${id}`, toDto(values))
          .then((value) => {
            setSubmitError(false);
            setSubmitting(false);
            navigate(-1);
          })
          .catch((reason) => {
            console.error(reason);
            setSubmitError(true);
            setSubmitting(false);
          });
      }
    },
  });

  const {
    errors,
    resetForm,
    touched,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    getFieldProps,
  } = formik;

  const getControlUnit = useCallback(async () => {
    const axiosClient = await GetVcDeviceApiClient(instance);
    if (axiosClient) {
      axiosClient.get<ControlUnit>(`control-units/${id}`).then((value) => {
        setControlUnit(value.data);
        resetForm({ values: toFormModel(value.data) });
      });
    }
  }, [id, instance, resetForm]);

  useEffect(() => {
    getControlUnit();
  }, [getControlUnit]);

  return (
    <Card>
      <CardContent>
        <Stack direction="row" spacing={2} mb={5}>
          <IconButton onClick={() => navigate(-1)}>
            <Icon icon={arrowBackFill} />
          </IconButton>
          <Typography variant="h4" gutterBottom>
            Control Unit - {controlUnit?.name ?? "Not configured"}
          </Typography>
        </Stack>
        <FormikProvider value={formik}>
          {controlUnit && (
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={3}>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Id"
                    value={controlUnit.id}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                    disabled={true}
                  />
                  <TextField
                    fullWidth
                    label="Device id"
                    value={controlUnit?.deviceForeignId}
                    error={Boolean(touched.location && errors.location)}
                    helperText={touched.location && errors.location}
                    disabled={true}
                  />
                </Stack>
                <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
                  <TextField
                    fullWidth
                    label="Name"
                    {...getFieldProps("name")}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    fullWidth
                    label="Location"
                    {...getFieldProps("location")}
                    error={Boolean(touched.location && errors.location)}
                    helperText={touched.location && errors.location}
                  />
                </Stack>
                {submitError && (
                  <Alert severity="error">
                    <AlertTitle>Submission error</AlertTitle>
                  </Alert>
                )}
                <LoadingButton
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                  loading={isSubmitting}
                >
                  Update
                </LoadingButton>
              </Stack>
            </Form>
          )}
        </FormikProvider>
      </CardContent>
    </Card>
  );
};
export default EditControlUnitPage;
