import { Link as RouterLink } from "react-router-dom";
// material
import { Box, Card, Link, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ControlUnit } from "../../Models/ControlUnit";
import { ControlUnitState } from "../../Models/ControlUnitState";
import { mockImgControlUnit } from "../../utils/mockImages";
import { ControlUnitStatus } from "../../Models/ControlUnitStatus";
// utils

// ----------------------------------------------------------------------

const ProductImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "scale-down",
  position: "absolute",
});

// ----------------------------------------------------------------------

const ControlUnitCard: React.FC<{
  controlUnit: ControlUnit;
  status?: ControlUnitStatus;
}> = ({ controlUnit, status }) => {
  return (
    <Card>
      <Link
        to={controlUnit.id}
        color="inherit"
        underline="hover"
        component={RouterLink}
      >
        <Stack spacing={2} sx={{ p: 3 }}>
          <Typography variant="subtitle2" noWrap>
            Name: {controlUnit.name ?? "Not configured"}
          </Typography>
          <Typography>
            Location: {controlUnit.location ?? "Not configured"}
          </Typography>
          <Typography>Device id: {controlUnit.deviceForeignId}</Typography>
          <Typography>
            Status:{" "}
            <span
              color={
                (status?.state === ControlUnitState.idle && "blue") || "green"
              }
            >
              {ControlUnitState[status?.state ?? ControlUnitState.idle]}
            </span>
          </Typography>
          <Typography>
            Last seen at:{" "}
            {status?.updatedAt
              ? `${new Date(
                  status.updatedAt
                ).toLocaleDateString()} - ${new Date(
                  status.updatedAt
                ).toLocaleTimeString()}`
              : "Not seen"}
          </Typography>
          <Box sx={{ pt: "100%", position: "relative" }}>
            <ProductImgStyle alt={controlUnit.name} src={mockImgControlUnit} />
          </Box>
        </Stack>
      </Link>
    </Card>
  );
};

export default ControlUnitCard;
