import React, { useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { GetVcApiClient } from "../../../utils/HttpClient";
import { Cycle } from "../../../Models/DeviceData/Cycle";
import { DataPoint } from "../../../Models/DeviceData/DataPoint";
import { Stack, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";

const MinimalCycleDetails: React.FC<{
  cycleId?: string;
  existingCycle?: Cycle;
  dataPoints?: ReadonlyArray<DataPoint>;
}> = ({ cycleId, existingCycle, dataPoints }) => {
  const { instance } = useMsal();
  const [cycle, setCycle] = useState<Cycle | null>(existingCycle ?? null);
  const [vaccumSensorValues, setVaccumSensorValues] = useState<
    Map<number, number>
  >(new Map<number, number>());

  const getCycles = useCallback(async () => {
    const axiosClient = await GetVcApiClient(instance);

    if (axiosClient && cycleId) {
      axiosClient.get<Cycle>(`device-data/cycles/${cycleId}`).then((value) => {
        setCycle(value.data);
      });
    }
  }, [instance, cycleId]);

  const getCycleDataPoints = useCallback(async () => {
    const axiosClient = await GetVcApiClient(instance);

    if (axiosClient && cycle && !dataPoints) {
      axiosClient
        .get<ReadonlyArray<DataPoint>>(
          `device-data/cycles/${cycle.id}/data-points`
        )
        .then((value) => {
          let vaccumSensorValues = new Map<number, number>();

          for (let dataPoint of value.data) {
            let ms =
              new Date(dataPoint.time).getTime() -
              new Date(cycle.time).getTime();
            vaccumSensorValues.set(ms, dataPoint.vacuum ?? 0);
          }
          setVaccumSensorValues(vaccumSensorValues);
        });
    }
  }, [instance, cycle, dataPoints]);

  useEffect(() => {
    getCycles();
  }, [getCycles]);

  useEffect(() => {
    getCycleDataPoints();
  }, [getCycleDataPoints]);

  useEffect(() => {
    if (dataPoints && existingCycle) {
      let vaccumSensorValues = new Map<number, number>();

      for (let dataPoint of dataPoints) {
        let ms =
          new Date(dataPoint.time).getTime() -
          new Date(existingCycle.time).getTime();
        vaccumSensorValues.set(ms, dataPoint.vacuum ?? 0);
      }
      setVaccumSensorValues(vaccumSensorValues);
    }
  }, [existingCycle, dataPoints]);

  useEffect(() => {
    if (existingCycle) {
      setCycle(existingCycle);
    }
  }, [existingCycle]);

  return (
    <Stack direction="row" spacing={2}>
      <Stack direction="column" spacing={2}>
        <Typography>Time started:</Typography>
        <Typography>Pressure:</Typography>
        <Typography>Vacuum:</Typography>
        <Typography>Vacuum material:</Typography>
      </Stack>
      <Stack direction="column" spacing={2}>
        <Typography>{`${new Date(
          cycle?.time ?? new Date()
        ).toLocaleTimeString()}`}</Typography>
        <Typography>{cycle?.pAvg}</Typography>
        <Typography>{cycle?.vAvg}</Typography>
        <Typography>{cycle?.vmAvg}</Typography>
      </Stack>
      <Stack direction="column">
        {cycle && (
          <ReactApexChart
            type="line"
            series={[
              {
                name: "Vacuum",
                data: Array.from(vaccumSensorValues).map((value) => {
                  return { x: value[0] / 1000, y: value[1] };
                }),
              },
            ]}
            options={{
              xaxis: {
                type: "numeric",
                title: { text: "Seconds" },
                min: 0,
                max: 10,
              },
              yaxis: {
                min: 0,
                title: { text: "Vacuum" },
              },
              chart: { toolbar: { show: false } },
            }}
            width={500}
            height={150}
          />
        )}
      </Stack>
    </Stack>
  );
};
export default MinimalCycleDetails;
