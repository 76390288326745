// material
import { useMsal } from "@azure/msal-react";
import { Button } from "@mui/material";
import { loginRequest } from "../authConfig";
import React from "react";

// ----------------------------------------------------------------------

const LoginForm: React.FC = () => {
  const { instance, inProgress } = useMsal();

  return (
    <Button
      fullWidth
      size="large"
      type="submit"
      variant="contained"
      disabled={inProgress === "login" || inProgress === "handleRedirect"}
      onClick={() => instance.loginRedirect(loginRequest)}
    >
      Login
    </Button>
  );
};
export default LoginForm;
