import React, { useCallback, useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Stack,
  Typography,
  Grid,
} from "@mui/material";
import { Icon } from "@iconify/react";
import arrowBackFill from "@iconify/icons-eva/arrow-back-fill";
import Page from "../components/Page";
import { useMsal } from "@azure/msal-react";
import { useNavigate, useParams } from "react-router-dom";
import { GetVcApiClient } from "../utils/HttpClient";
import { Run } from "../Models/DeviceData/Run";
import CycleDetails from "../components/TestsRuns/TestResults/CycleDetails";
import RunResultDetails from "../components/Runs/RunResultDetails";
import { Cycle } from "../Models/DeviceData/Cycle";
import AverageGraph, {
  AverageRecordType,
} from "../components/TestsRuns/AverageGraph";

const RunPage: React.FC = () => {
  const { instance } = useMsal();
  const { id } = useParams();
  const navigate = useNavigate();
  const [cycleIndex, setCycleIndex] = useState<number>(0);

  const [avg, setAvg] = useState<AverageRecordType[]>();
  const [run, setRun] = useState<Run | null>(null);
  const [cycles, setCycles] = useState<Cycle[]>([]);

  const getRun = useCallback(async () => {
    if (id) {
      const axiosClient = await GetVcApiClient(instance);
      if (axiosClient) {
        axiosClient.get<Run>(`device-data/runs/${id}`).then((value) => {
          let average = value.data.atTestCycles?.map(
            (item): AverageRecordType => {
              return {
                id: item.id,
                recordTime: new Date(item.time),
                p: item.pAvg,
                v: item.vAvg,
                vm: item.vmAvg,
                pos: item.valvePos,
                diff:
                  item?.vAvg && item.vmAvg
                    ? (item.vAvg - item.vmAvg).toFixed(2)
                    : null,
              };
            }
          );
          setAvg(average);
          setRun(value.data);
          let orderedCycles = [...(value.data.atTestCycles ?? [])].sort(
            (a, b) => (b.cycle ?? 0) - (a.cycle ?? 0)
          );
          setCycles(orderedCycles.reverse());
          if (orderedCycles.length > 0) {
            setCycleIndex(orderedCycles.length - 1);
          }
        });
      }
    }
  }, [id, instance]);

  useEffect(() => {
    getRun();
  }, [getRun]);

  const currentCycle = cycles.at(cycleIndex);
  return (
    <Page title="Test run">
      {!run && <>No run found</>}
      {run && (
        <Container>
          <Stack direction="row" spacing={2} mb={5}>
            <IconButton onClick={() => navigate(-1)}>
              <Icon icon={arrowBackFill} />
            </IconButton>
            <Typography variant="h4" gutterBottom>
              Run
            </Typography>
          </Stack>
          <Stack direction="column" spacing={2}>
            <Card>
              <CardHeader title="Run Results" />
              <CardContent>
                <RunResultDetails results={run.atTestRunResult} />
              </CardContent>
            </Card>
            <Card>
              <CardHeader
                title={() => `Cycle ${cycleIndex + 1} of ${cycles.length}`}
              />

              <CardContent>
                <Stack flexDirection="row" alignItems="center">
                  <Grid
                    width={50}
                    m={2}
                    onClick={() => {
                      if (cycleIndex > 0) {
                        setCycleIndex(cycleIndex - 1);
                      }
                    }}
                  >
                    {cycleIndex > 0 && (
                      <Icon icon="material-symbols:arrow-back-ios" width={40} />
                    )}
                  </Grid>
                  <CycleDetails cycleId={currentCycle?.id} />{" "}
                  <Grid
                    width={50}
                    m={2}
                    onClick={() => {
                      if (cycleIndex !== cycles.length - 1) {
                        setCycleIndex((ix) => ix + 1);
                      }
                    }}
                  >
                    {cycleIndex < cycles.length - 1 && (
                      <Icon
                        icon="material-symbols:arrow-forward-ios"
                        width={40}
                      />
                    )}
                  </Grid>
                </Stack>
              </CardContent>
            </Card>
            {avg && (
              <Card>
                <CardHeader title="Average details" />
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <AverageGraph
                        chartType="p"
                        data={avg}
                        name="Average Pressure"
                        xAxisTitle="Cycles"
                        yAxisTitle="Pressure"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AverageGraph
                        chartType="v"
                        data={avg}
                        name="Average Vacuum"
                        xAxisTitle="Cycles"
                        yAxisTitle="Vacuum"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AverageGraph
                        chartType="vm"
                        data={avg}
                        name="Average Vacuum Material"
                        xAxisTitle="Cycles"
                        yAxisTitle="Vacuum material"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AverageGraph
                        chartType="diff"
                        data={avg}
                        name="Differential Vacuum"
                        xAxisTitle="Cycles"
                        yAxisTitle="differential vacuum"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <AverageGraph
                        chartType="pos"
                        data={avg}
                        name="Historical Valve Position"
                        xAxisTitle="Cycles"
                        yAxisTitle="Valve Position"
                      />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            )}
          </Stack>
        </Container>
      )}
    </Page>
  );
};
export default RunPage;
