// material
import { Box, Grid, Container, Typography } from "@mui/material";
// components
import Page from "../components/Page";
import React, { useContext } from "react";
import DeviceFleet from "../components/Dashboard/DeviceFleet";
import { AccountContext } from "../contexts/AccountProvider";

// ----------------------------------------------------------------------

const DashboardApp: React.FC = () => {
  const { graphData } = useContext(AccountContext);

  return (
    <Page title="Dashboard">
      <Container maxWidth="xl">
        {graphData && (
          <Box sx={{ pb: 5 }}>
            <Typography variant="h4">
              Hej {graphData.displayName}, välkommen tillbaka
            </Typography>
          </Box>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <DeviceFleet />
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};
export default DashboardApp;
