export enum ControlUnitState {
  idle = 0,
  start = 1,
  valve_close = 2,
  charge = 3,
  valve_open = 4,
  discharge = 5,
  stop = 6,
  emergency_stop = 7,
  safety_discharge = 8,
  pressure_guard_stop = 9,
  sensor_cleaning = 10,
  acu_test = 11,
  function_check = 12,
  filter_chock = 13,
  calibration_cycle = 14,
  evaluation_cycle = 15,
  pipe_emptying = 16,
}
