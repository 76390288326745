import React, { useEffect, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Alert, AlertTitle, Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as Yup from "yup";
import { GetVcApiClient } from "../../utils/HttpClient";
import { CreateTestDto } from "../../Models/Dto/CreateTestDto";
import { useMsal } from "@azure/msal-react";
import TestRunsSelect from "./TestRunsSelect";

interface FormModel {
  name: string;
  testRunIds: string[];
}

const toFormModel = (model: CreateTestDto): FormModel => {
  return {
    name: model.name ?? "",
    testRunIds: model.testRunIds ?? [],
  };
};

const toDto = (model: FormModel): CreateTestDto => {
  return {
    name: model.name === "" ? undefined : model.name,
    testRunIds: model.testRunIds,
  };
};

const CreateTestForm: React.FC<{
  onCreatedNewTest: (newId: string) => void;
}> = ({ onCreatedNewTest }) => {
  const { instance } = useMsal();
  const [submitError, setSubmitError] = useState(false);

  const RegisterSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
  });

  const formik = useFormik({
    initialValues: toFormModel({}),
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      const axiosClient = await GetVcApiClient(instance);
      if (axiosClient) {
        axiosClient
          .post<string>(`tests`, toDto(values))
          .then((value) => {
            setSubmitError(false);
            onCreatedNewTest(value.data);
            setSubmitting(false);
          })
          .catch((reason) => {
            console.error(reason);
            setSubmitError(true);
            setSubmitting(false);
          });
      }
    },
  });

  const {
    errors,
    resetForm,
    touched,
    handleSubmit,
    isSubmitting,
    setSubmitting,
    getFieldProps,
    setFieldValue,
    values,
  } = formik;

  useEffect(() => {
    resetForm({ values: toFormModel({}) });
  }, [resetForm]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              label="Name"
              {...getFieldProps("name")}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
          </Stack>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TestRunsSelect
              selectedTestRunIds={values.testRunIds}
              onSelectedTestRun={(newTestRunIds) =>
                setFieldValue("testRunIds", newTestRunIds)
              }
              disabled={false}
            />
          </Stack>

          {submitError && (
            <Alert severity="error">
              <AlertTitle>Submission error</AlertTitle>
            </Alert>
          )}
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Create
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
};
export default CreateTestForm;
