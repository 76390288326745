// material
import {
  Card,
  CardHeader,
  Grid,
  CardContent,
  Stack,
  Typography,
  IconButton,
} from "@mui/material";
import { Icon } from "@iconify/react";

//
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { GetVcDeviceApiClient } from "../../utils/HttpClient";
import { ControlUnit } from "../../Models/ControlUnit";
import { ControlUnitState } from "../../Models/ControlUnitState";
import { StatusesContext } from "../../contexts/StatusesProvider";
import MinimalCycleDetails from "../TestsRuns/TestResults/MinimalCycleDetails";
import { useNavigate } from "react-router-dom";
import arrowForwardFill from "@iconify/icons-eva/arrow-forward-fill";
import { DeviceDataContext } from "../../contexts/DeviceDataProvider";

// ----------------------------------------------------------------------

const DeviceFleet: React.FC = () => {
  const { instance } = useMsal();
  const navigate = useNavigate();
  const [controlUnits, setControlUnits] = useState<ReadonlyArray<ControlUnit>>(
    []
  );

  const { statuses } = useContext(StatusesContext);
  const { latestControlUnitCycles, dataPoints } = useContext(DeviceDataContext);

  const getControlUnits = useCallback(async () => {
    const axiosClient = await GetVcDeviceApiClient(instance);
    if (axiosClient) {
      axiosClient
        .get<ReadonlyArray<ControlUnit>>(`control-units?configured=true`)
        .then((value) => {
          setControlUnits(value.data);
        });
    }
  }, [instance]);

  useEffect(() => {
    getControlUnits();
  }, [getControlUnits]);

  return (
    <Card>
      <CardHeader
        title="Device Fleet"
        subheader={controlUnits.length + " configured devices"}
      />
      <CardContent>
        <Grid container spacing={2}>
          {controlUnits.map((controlUnit) => (
            <Grid item xs={12} key={controlUnit.deviceForeignId}>
              <Grid container spacing={2}>
                <Grid item xs={3}>
                  <Card>
                    <CardHeader
                      title={
                        <Stack
                          direction="row"
                          spacing={2}
                          justifyContent={"space-between"}
                          alignItems="center"
                        >
                          <Typography>Control unit</Typography>
                          <IconButton
                            onClick={() =>
                              navigate(
                                `/dashboard/control-units/${controlUnit.id}`
                              )
                            }
                          >
                            <Icon icon={arrowForwardFill} />
                          </IconButton>
                        </Stack>
                      }
                    />
                    <CardContent>
                      <Stack direction="row" spacing={2}>
                        <Stack direction="column" spacing={2}>
                          <Typography>Name:</Typography>
                          <Typography>Location:</Typography>
                          <Typography>Status:</Typography>
                          <Typography>Last status at:</Typography>
                        </Stack>
                        <Stack direction="column" spacing={2}>
                          <Typography>
                            {controlUnit.name ?? "Not configured"}
                          </Typography>
                          <Typography>
                            {controlUnit.location ?? "Not configured"}
                          </Typography>
                          {statuses && (
                            <Typography
                              color={
                                (statuses.get(controlUnit.id)?.state ===
                                  ControlUnitState.idle &&
                                  "blue") ||
                                "green"
                              }
                            >
                              {
                                ControlUnitState[
                                  statuses.get(controlUnit.id)?.state ??
                                    ControlUnitState.idle
                                ]
                              }
                            </Typography>
                          )}
                          {statuses && (
                            <Typography>
                              {statuses.get(controlUnit.id)?.updatedAt
                                ? `${new Date(
                                    statuses.get(controlUnit.id)?.updatedAt ?? 0
                                  ).toLocaleDateString()} - ${new Date(
                                    statuses.get(controlUnit.id)?.updatedAt ?? 0
                                  ).toLocaleTimeString()}`
                                : "Not seen"}
                            </Typography>
                          )}
                        </Stack>
                      </Stack>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={9}>
                  {latestControlUnitCycles?.has(
                    controlUnit.deviceForeignId
                  ) && (
                    <Card>
                      <CardHeader title="Current cycle" />
                      <CardContent>
                        <MinimalCycleDetails
                          existingCycle={latestControlUnitCycles?.get(
                            controlUnit.deviceForeignId
                          )}
                          dataPoints={dataPoints?.get(
                            latestControlUnitCycles?.get(
                              controlUnit.deviceForeignId
                            )?.id ?? ""
                          )}
                        />
                      </CardContent>
                    </Card>
                  )}
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
export default DeviceFleet;
