import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  FormGroup,
  FormHelperText,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import arrowBackFill from "@iconify/icons-eva/arrow-back-fill";
import { useNavigate } from "react-router-dom";
import Page from "../../components/Page";
import { CustomerInformationState } from "../../components/TestsRuns/CustomerInformations/CustomerInformationSection";
import { TestEnvironmentState } from "../../components/TestsRuns/TestEnvironments/TestEnvironmentSection";
import { ConveyorState } from "../../components/TestsRuns/Conveyors/ConveyorSection";
import { MaterialState } from "../../components/TestsRuns/Materials/MaterialSection";
import { LoadingButton } from "@mui/lab";
import { GetVcApiClient } from "../../utils/HttpClient";
import { useMsal } from "@azure/msal-react";
import * as Yup from "yup";
import { FormikProvider, useFormik } from "formik";
import CreateTestRunDto from "../../Models/Dto/CreateTestRunDto";
import CreateTestRunFormFields from "../../components/TestsRuns/CreateTestRunFormFields";
import ControlUnitSection from "../../components/TestsRuns/ControlUnitSection";

export interface TestRunFormModel {
  name?: string | null;
  materialName?: string | null;
  conveyorDim?: string | null;
  sensingMaterialSidePosition?: string | null;
  filterType?: string | null;
  filterSize?: string | null;
  pumpSize?: number | null;
  note?: string | null;
}

const toFormModel = (model: CreateTestRunDto): TestRunFormModel => {
  return {
    name: model.name ?? null,
    materialName: model.materialName ?? null,
    conveyorDim: model.conveyorDim ?? null,
    sensingMaterialSidePosition: model.sensingMaterialSidePosition ?? null,
    filterType: model.filterType ?? null,
    filterSize: model.filterSize ?? null,
    pumpSize: model.pumpSize ?? null,
    note: model.note ?? null,
  };
};

interface CreateTestRunState {
  controlUnitId: string | null;
  customerInformationState: CustomerInformationState;
  testEnvironmentState: TestEnvironmentState;
  conveyorState: ConveyorState;
  materialState: MaterialState;
  isDirty: boolean;
  isSubmitting: boolean;
  hasError: boolean;
  hasSubmitError: boolean;
}

const initialState = (): CreateTestRunState => {
  return {
    controlUnitId: null,
    customerInformationState: { id: null, isCreating: false },
    testEnvironmentState: { id: null, isCreating: false },
    conveyorState: { id: null, isCreating: false },
    materialState: { id: null, isCreating: false },
    isDirty: false,
    isSubmitting: false,
    hasError: false,
    hasSubmitError: false,
  };
};

const CreateTestRunPage: React.FC = () => {
  const navigate = useNavigate();
  const { instance } = useMsal();

  const [createTestRunState, setCreateTestRunState] = useState(initialState);
  // const [customerName, setCustomerName] = useState<string | null>(null);
  // const [conveyorName, setConveyorName] = useState<string | null>(null);
  // const [testEnvironmentName, setTestEnvironmentName] = useState<string | null>(
  //   null
  // );
  // const [materialName, setMaterialName] = useState<string | null>(null);

  const RegisterSchema = Yup.object().shape({
    sensingMaterialSidePosition: Yup.string()
      .required("Sensing material side position is required")
      .nullable(),
    name: Yup.string()
      .required()
      .required("Sensing material side position is required")
      .nullable(),
    filterType: Yup.string().required("Filter type is required").nullable(),
    note: Yup.string().required("Note is required").nullable(),
    pumpSize: Yup.number().required("Note is required").nullable(),
  });

  const formik = useFormik({
    initialValues: toFormModel({}),
    validationSchema: RegisterSchema,
    onSubmit: () => validateAndSubmit(),
  });

  const {
    errors,
    resetForm,
    touched,
    handleSubmit,
    getFieldProps,
    values,
    submitForm,
  } = formik;

  useEffect(() => {
    resetForm({ values: toFormModel({}) });
  }, [resetForm]);

  const validateAndSubmit = async () => {
    let hasErrors =
      createTestRunState.customerInformationState.isCreating ||
      createTestRunState.testEnvironmentState.isCreating ||
      createTestRunState.conveyorState.isCreating ||
      createTestRunState.materialState.isCreating;
    let isSubmitting = !hasErrors;
    setCreateTestRunState({
      ...createTestRunState,
      isDirty: hasErrors,
      isSubmitting: isSubmitting,
      hasSubmitError: false,
    });
    if (isSubmitting) {
      const axiosClient = await GetVcApiClient(instance);
      if (axiosClient) {
        axiosClient
          .post<string>(`test-runs`, {
            name: values.name,
            materialName: values.materialName,
            controlUnitId: createTestRunState.controlUnitId,
            conveyorDim: values.conveyorDim,
            sensingMaterialSidePosition: values.sensingMaterialSidePosition,
            filterType: values.filterType,
            filterSize: values.filterSize,
            pumpSize: values.pumpSize,
            note: values.note,
            customerInformationId:
              createTestRunState.customerInformationState.id ?? undefined,
            testEnvironmentId:
              createTestRunState.testEnvironmentState.id ?? undefined,
            conveyorId: createTestRunState.conveyorState.id ?? undefined,
            materialId: createTestRunState.materialState.id ?? undefined,
          })
          .then((value) => {
            navigate(`/dashboard/test-runs/${value.data}`);
            // setCreateTestRunState(initialState);
          })
          .catch((reason) => {
            console.error(reason);
            setCreateTestRunState({
              ...createTestRunState,
              isSubmitting: false,
              hasSubmitError: true,
            });
          });
      }
    }
  };

  return (
    <Page title="Create new test run">
      <Container>
        <Stack direction="row" spacing={2} mb={5}>
          <IconButton onClick={() => navigate(-1)}>
            <Icon icon={arrowBackFill} />
          </IconButton>
          <Typography variant="h4" gutterBottom>
            Create new test run
          </Typography>
        </Stack>
        <Stack spacing={4}>
          <Card>
            <CardHeader title="Test run details" />
            <CardContent>
              <FormikProvider value={formik}>
                <CreateTestRunFormFields
                  getFieldProps={getFieldProps}
                  errors={errors}
                  touched={touched}
                  handleSubmit={handleSubmit}
                />
              </FormikProvider>
            </CardContent>
          </Card>
          <ControlUnitSection
            controlUnitId={createTestRunState.controlUnitId}
            onSetControlUnitId={(newId) => {
              setCreateTestRunState({
                ...createTestRunState,
                controlUnitId: newId,
              });
            }}
          />
          {/*<CustomerInformationSection*/}
          {/*  customerName={customerName}*/}
          {/*  customerInformationState={*/}
          {/*    createTestRunState.customerInformationState*/}
          {/*  }*/}
          {/*  onSetCustomerInformationState={(newState) =>*/}
          {/*    setCreateTestRunState({*/}
          {/*      ...createTestRunState,*/}
          {/*      customerInformationState: newState,*/}
          {/*    })*/}
          {/*  }*/}
          {/*  setCustomerName={setCustomerName}*/}
          {/*/>*/}
          {/*<TestEnvironmentSection*/}
          {/*  testEnvironmentName={testEnvironmentName}*/}
          {/*  testEnvironmentState={createTestRunState.testEnvironmentState}*/}
          {/*  setTestEnvironmentState={(newState) =>*/}
          {/*    setCreateTestRunState({*/}
          {/*      ...createTestRunState,*/}
          {/*      testEnvironmentState: newState,*/}
          {/*    })*/}
          {/*  }*/}
          {/*  setTestEnvironmentName={setTestEnvironmentName}*/}
          {/*/>*/}
          {/*<ConveyorSection*/}
          {/*  conveyorName={conveyorName}*/}
          {/*  conveyorState={createTestRunState.conveyorState}*/}
          {/*  setConveyorState={(newState) =>*/}
          {/*    setCreateTestRunState({*/}
          {/*      ...createTestRunState,*/}
          {/*      conveyorState: newState,*/}
          {/*    })*/}
          {/*  }*/}
          {/*  setConveyorName={setConveyorName}*/}
          {/*/>*/}
          {/*<MaterialSection*/}
          {/*  materialName={materialName}*/}
          {/*  materialState={createTestRunState.materialState}*/}
          {/*  setMaterialState={(newState) =>*/}
          {/*    setCreateTestRunState({*/}
          {/*      ...createTestRunState,*/}
          {/*      materialState: newState,*/}
          {/*    })*/}
          {/*  }*/}
          {/*  setMaterialName={setMaterialName}*/}
          {/*/>*/}
          <FormGroup>
            <LoadingButton
              size="large"
              type="submit"
              variant="contained"
              loading={createTestRunState.isSubmitting}
              disabled={
                createTestRunState.hasError || createTestRunState.isSubmitting
              }
              onClick={submitForm}
            >
              Create new test run
            </LoadingButton>
            {createTestRunState.hasError && (
              <FormHelperText>
                <Typography color="error">
                  Unsaved Changes. Please commit or discard changes before
                  submitting test run.
                </Typography>
              </FormHelperText>
            )}
            {createTestRunState.hasSubmitError && (
              <Typography color="error">Submit error</Typography>
            )}
          </FormGroup>
        </Stack>
      </Container>
    </Page>
  );
};

export default CreateTestRunPage;
