import React, { useCallback, useEffect, useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { ControlUnit } from "../../../Models/ControlUnit";
import { GetVcDeviceApiClient } from "../../../utils/HttpClient";
import { useMsal } from "@azure/msal-react";

const ControlUnitSelect: React.FC<{
  selectedControlUnitId: string | null;
  onSelectedControlUnit: (controlUnit: ControlUnit | null) => void;
}> = ({ selectedControlUnitId, onSelectedControlUnit }) => {
  const { instance } = useMsal();
  const [controlUnits, setControlUnits] = useState<ReadonlyArray<ControlUnit>>(
    []
  );

  const getControlUnits = useCallback(async () => {
    const axiosClient = await GetVcDeviceApiClient(instance);
    if (axiosClient) {
      axiosClient
        .get<ReadonlyArray<ControlUnit>>(`control-units`)
        .then((value) => {
          setControlUnits(value.data);
        });
    }
  }, [instance]);

  let hasControlUnitInList =
    controlUnits.find((value) => value.id === selectedControlUnitId) !==
    undefined;

  useEffect(() => {
    getControlUnits();
  }, [getControlUnits, selectedControlUnitId]);

  return (
    <FormControl fullWidth>
      <InputLabel id="control-unit-select-label">Control units</InputLabel>
      <Select
        labelId="control-unit-select-label"
        id="control-unit-select"
        value={hasControlUnitInList ? selectedControlUnitId ?? "" : ""}
        label="Control Unit"
        onChange={(event) =>
          onSelectedControlUnit(
            controlUnits.find((value) => value.id === event.target.value) ??
              null
          )
        }
      >
        {controlUnits.map((controlUnit) => (
          <MenuItem key={controlUnit.id} value={controlUnit.id}>
            {controlUnit.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
export default ControlUnitSelect;
