import React from "react";

import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import Router from "./routes";
import ScrollToTop from "./components/ScrollToTop";
import { BaseOptionChartStyle } from "./components/Charts/BaseOptionChart";
import { AccountProvider } from "./contexts/AccountProvider";
import { useNavigate } from "react-router-dom";
import { CustomNavigationClient } from "./utils/NavigationClient";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { DataProvider } from "./contexts/DataProvider";
import { StatusesProvider } from "./contexts/StatusesProvider";
import { DeviceDataProvider } from "./contexts/DeviceDataProvider";

const App: React.FC<{ pca: PublicClientApplication }> = ({
  pca,
}): JSX.Element => {
  // The next 3 lines are optional. This is how you configure MSAL to take advantage of the router's navigate functions when MSAL redirects between Pages in your app
  const navigate = useNavigate();
  const navigationClient = new CustomNavigationClient(navigate);
  pca.setNavigationClient(navigationClient);

  return (
    <MsalProvider instance={pca}>
      <ThemeConfig>
        <ScrollToTop />
        <GlobalStyles />
        <BaseOptionChartStyle />
        <React.StrictMode>
          <AccountProvider>
            <DataProvider>
              <StatusesProvider>
                <DeviceDataProvider>
                  <Router />
                </DeviceDataProvider>
              </StatusesProvider>
            </DataProvider>
          </AccountProvider>
        </React.StrictMode>
      </ThemeConfig>
    </MsalProvider>
  );
};

export default App;
