import React from "react";
import { Stack, TextField } from "@mui/material";
import { TestRun } from "../../Models/TestRun";

const TestRunDetails: React.FC<{ testRun: TestRun }> = ({ testRun }) => {
  return (
    <Stack spacing={3}>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <TextField
          fullWidth
          type={"text"}
          label="Name"
          value={testRun.name ?? "-"}
        />
        <TextField
          fullWidth
          type={"text"}
          label="Material"
          value={testRun.materialName ?? "-"}
        />
        <TextField
          fullWidth
          type={"text"}
          label="Conveyor dimension"
          value={testRun.conveyorDim ?? ""}
        />
        <TextField
          fullWidth
          label="Sensing material side position"
          value={testRun.sensingMaterialSidePosition ?? ""}
        />
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <TextField
          fullWidth
          label="Filter type"
          value={testRun.filterType ?? ""}
        />
        <TextField
          fullWidth
          type={"text"}
          label="Filter size"
          value={testRun.filterSize ?? ""}
        />
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <TextField
          fullWidth
          type={"number"}
          label="Pump size"
          value={testRun.pumpSize ?? ""}
        />
      </Stack>
      <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
        <TextField fullWidth label="Note" value={testRun.note ?? ""} />
      </Stack>
    </Stack>
  );
};
export default TestRunDetails;
