import axios, { AxiosRequestHeaders } from "axios";
import { apiConfig } from "../apiConfig";
import { apiTokenRequest } from "../authConfig";
import { IPublicClientApplication } from "@azure/msal-browser";

export const GetVcApiClient = async (instance: IPublicClientApplication) => {
  const account = instance.getActiveAccount();
  if (account) {
    let response = await instance.acquireTokenSilent({
      ...apiTokenRequest,
      account: account,
    });
    const headers: AxiosRequestHeaders = {
      Authorization: "Bearer " + response.accessToken,
    };

    return axios.create({
      baseURL: apiConfig.apiUrl,
      headers,
    });
  }
};

export const GetVcDeviceApiClient = async (
  instance: IPublicClientApplication
) => {
  const account = instance.getActiveAccount();
  if (account) {
    let response = await instance.acquireTokenSilent({
      ...apiTokenRequest,
      account: account,
    });
    const headers: AxiosRequestHeaders = {
      Authorization: "Bearer " + response.accessToken,
    };

    return axios.create({
      baseURL: apiConfig.deviceApiUrl,
      headers,
    });
  }
};
