import React from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import arrowBackFill from "@iconify/icons-eva/arrow-back-fill";
import { useNavigate } from "react-router-dom";
import Page from "../../components/Page";
import CreateTestForm from "../../components/Tests/CreateTestForm";

const CreateTestPage: React.FC = () => {
  const navigate = useNavigate();

  return (
    <Page title="Create new test">
      <Container>
        <Stack direction="row" spacing={2} mb={5}>
          <IconButton onClick={() => navigate(-1)}>
            <Icon icon={arrowBackFill} />
          </IconButton>
          <Typography variant="h4" gutterBottom>
            Create new test
          </Typography>
        </Stack>
        <Stack direction="column" spacing={5}>
          <Card>
            <CardHeader title={"Create new test"} />
            <CardContent>
              <CreateTestForm
                onCreatedNewTest={(newId) =>
                  navigate(`/dashboard/tests/${newId}`)
                }
              />
            </CardContent>
          </Card>
        </Stack>
      </Container>
    </Page>
  );
};

export default CreateTestPage;
