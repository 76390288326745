import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { Icon } from "@iconify/react";
import arrowBackFill from "@iconify/icons-eva/arrow-back-fill";
import Page from "../../components/Page";
import { Test } from "../../Models/Test";
import { GetVcApiClient } from "../../utils/HttpClient";
import { useMsal } from "@azure/msal-react";
import TestRunsTableCard from "../../components/TestsRuns/TestRunsTableCard";
import AddTestRunButton from "../../components/TestsRuns/AddTestRunButton";

const TestPage: React.FC = () => {
  const { instance } = useMsal();
  const { id } = useParams();
  const [test, setTest] = useState<Test | null>(null);
  const [shouldReload, setShouldReload] = useState(true);
  const navigate = useNavigate();

  const getTest = useCallback(async () => {
    if (shouldReload) {
      if (id) {
        const axiosClient = await GetVcApiClient(instance);
        if (axiosClient) {
          axiosClient.get<Test>(`tests/${id}`).then((value) => {
            setTest(value.data);
          });
        }
      }
    }
  }, [id, instance, shouldReload]);

  useEffect(() => {
    getTest();
  }, [getTest]);

  return (
    <Page title="Test">
      {!test && <>No test found</>}
      {test && (
        <Container>
          <Stack direction="row" spacing={2} mb={5}>
            <IconButton onClick={() => navigate(-1)}>
              <Icon icon={arrowBackFill} />
            </IconButton>
            <Typography variant="h4" gutterBottom>
              Test - {test.name}
            </Typography>
          </Stack>
          <Stack direction="column" spacing={2}>
            <Card>
              <CardHeader title="Test details" />
              <CardContent>
                <Stack direction="row" spacing={2}>
                  <Stack direction="column">
                    <Typography>Created by:</Typography>
                    <Typography>Created at:</Typography>
                  </Stack>
                  <Stack direction="column">
                    <Typography>{test.createdBy?.name}</Typography>
                    <Typography>
                      {new Date(test.createdAt)?.toLocaleDateString()} -{" "}
                      {new Date(test.createdAt).toLocaleTimeString()}
                    </Typography>
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
            <TestRunsTableCard
              testRuns={test.testRuns ?? []}
              setShouldReload={setShouldReload}
            />
            <AddTestRunButton
              testId={test.id}
              onAddedTestRuns={() => getTest()}
            />
          </Stack>
        </Container>
      )}
    </Page>
  );
};

export default TestPage;
