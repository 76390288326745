import { Icon } from "@iconify/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// material
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Grid,
  IconButton,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import arrowBackFill from "@iconify/icons-eva/arrow-back-fill";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// components
import Page from "../../components/Page";

import { styled } from "@mui/material/styles";
import playCircleFill from "@iconify/icons-eva/play-circle-fill";
import stopCircleFill from "@iconify/icons-eva/stop-circle-fill";
import edit2Fill from "@iconify/icons-eva/edit-2-fill";
import { ControlUnit } from "../../Models/ControlUnit";
import { ControlUnitState } from "../../Models/ControlUnitState";
import { mockImgControlUnit } from "../../utils/mockImages";
import { GetVcDeviceApiClient } from "../../utils/HttpClient";
import { useMsal } from "@azure/msal-react";
import { ControlUnitConfig } from "../../Models/ControlUnitConfig";
import ControlUnitConfigDialog from "../../components/ControlUnits/ControlUnitConfigDialog";
import { LoadingButton } from "@mui/lab";
import { ControlUnitStatus } from "../../Models/ControlUnitStatus";
import { StatusesContext } from "../../contexts/StatusesProvider";
import CycleDetails from "../../components/TestsRuns/TestResults/CycleDetails";
import RunTestForm from "../../components/ControlUnits/RunTestForm";
import { ControlUnitParamsDto } from "../../Models/Dto/StartCommandDto";
import RunsListCard from "../../components/Runs/RunsListCard";
import { DeviceDataContext } from "../../contexts/DeviceDataProvider";

const ControlUnitImgStyle = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ControlUnitPage: React.FC = () => {
  const { id } = useParams();
  const { instance } = useMsal();
  const { statuses } = useContext(StatusesContext);
  const { latestControlUnitCycles, dataPoints } = useContext(DeviceDataContext);

  const [controlUnit, setControlUnit] = useState<ControlUnit | null>(null);
  const [config, setConfig] = useState<ControlUnitConfig | null>(null);
  const [openConfigDialog, setOpenConfigDialog] = useState(false);
  const [openStartConfirmationModal, setOpenStartConfirmationModal] =
    useState(false);
  const [isStarting, setIsStarting] = useState(false);
  const [started, setStarted] = useState(false);
  const [startFailed, setStartFailed] = useState(false);
  const [startTimedOut, setStartTimedOut] = useState(false);
  const [failureReason, setFailureReason] = useState<string | null>("");
  const navigate = useNavigate();

  const [deviceStatus, setDeviceStatus] = useState<ControlUnitStatus | null>(
    null
  );
  const [startCycles, setStartCycles] = useState<string | undefined>(undefined);

  const [startTime, setStartTime] = useState(new Date());

  const [controlUnitParams, setcontrolUnitParams] = useState<
    ControlUnitParamsDto | {}
  >({});

  useEffect(() => {
    if (statuses && controlUnit?.id) {
      let newStatus = statuses.get(controlUnit?.id);
      if (newStatus) {
        if (started && deviceStatus?.state === ControlUnitState.idle) {
          setStarted(false);
        } else if (
          !started &&
          deviceStatus?.state !== ControlUnitState.idle &&
          deviceStatus?.state
        ) {
          setStarted(true);
        }
        if (
          deviceStatus?.state !== newStatus.state ||
          (deviceStatus?.updatedAt !== undefined &&
            newStatus.updatedAt !== undefined &&
            deviceStatus?.updatedAt < newStatus.updatedAt)
        ) {
          setDeviceStatus(newStatus);
        }

        let now = new Date();
        let timeout = startTime;
        timeout.setSeconds(timeout.getSeconds() + 15);

        if (isStarting && now < timeout) {
          if (newStatus.state !== ControlUnitState.idle) {
            setStarted(true);
            setIsStarting(false);
            console.log("start confirmed");
          }
        } else if (isStarting && now >= timeout) {
          setIsStarting(false);
          setStartTimedOut(true);
          console.log("Start timed out");
        }
      }
    }
  }, [
    started,
    startTime,
    deviceStatus?.state,
    deviceStatus?.updatedAt,
    isStarting,
    controlUnit?.id,
    statuses,
  ]);

  const startControlUnit = async (data: ControlUnitParamsDto) => {
    const axiosClient = await GetVcDeviceApiClient(instance);
    if (axiosClient) {
      setIsStarting(true);
      setFailureReason(null);
      setStartFailed(false);
      setStartTimedOut(false);
      axiosClient
        .post(`control-units/${controlUnit?.deviceForeignId}/start`, data)
        .then((value) => {
          let now = new Date();
          setStartTime(now);
          setOpenStartConfirmationModal(false);
        })
        .catch((reason) => {
          setIsStarting(false);
          setStartFailed(true);
          if (reason.response) {
            setFailureReason(reason.response.data.data);
          }
        });
    }
  };

  const stopControlUnit = async () => {
    const axiosClient = await GetVcDeviceApiClient(instance);
    if (axiosClient) {
      axiosClient
        .post(`control-units/${controlUnit?.deviceForeignId}/stop`)
        .then((value) => {
          console.log(value.data);
        });
    }
  };
  const getControlUnitConfig = async () => {
    const axiosClient = await GetVcDeviceApiClient(instance);
    if (axiosClient) {
      axiosClient
        .post<{ result: ControlUnitConfig }>(
          `control-units/${controlUnit?.deviceForeignId}/get-config`
        )
        .then((value) => {
          setConfig(value.data.result);
          setOpenConfigDialog(true);
        });
    }
  };

  const getControlUnit = useCallback(async () => {
    const axiosClient = await GetVcDeviceApiClient(instance);
    if (axiosClient) {
      axiosClient.get<ControlUnit>(`control-units/${id}`).then((value) => {
        setControlUnit(value.data);
      });
    }
  }, [id, instance]);

  useEffect(() => {
    getControlUnit();
  }, [getControlUnit]);

  useEffect(() => {}, []);

  return (
    <Page title="Control Unit">
      {!controlUnit && <>No control unit found</>}
      {controlUnit && (
        <Container>
          <Stack direction="row" spacing={2} mb={5}>
            <IconButton onClick={() => navigate(-1)}>
              <Icon icon={arrowBackFill} />
            </IconButton>
            <Typography variant="h4" gutterBottom>
              Control Unit - {controlUnit.name ?? "Not configured"}
            </Typography>
          </Stack>
          <Stack direction="column" spacing={5}>
            <Card>
              <CardHeader
                title={controlUnit.name ?? "Not configured"}
                action={
                  <Button
                    color="info"
                    variant="contained"
                    startIcon={<Icon icon={edit2Fill} />}
                    onClick={() => navigate("edit")}
                  >
                    Edit
                  </Button>
                }
              />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Stack direction="row" spacing={2}>
                          <Stack direction="column" spacing={2}>
                            <Typography>Name:</Typography>
                            <Typography>Location:</Typography>
                            <Typography>Device id:</Typography>
                            <Typography>Status:</Typography>
                            <Typography>Last status at:</Typography>
                          </Stack>
                          <Stack direction="column" spacing={2}>
                            <Typography>
                              {controlUnit.name ?? "Not configured"}
                            </Typography>
                            <Typography>
                              {controlUnit.location ?? "Not configured"}
                            </Typography>
                            <Typography>
                              {controlUnit.deviceForeignId}
                            </Typography>
                            <Typography
                              color={
                                (deviceStatus?.state ===
                                  ControlUnitState.idle &&
                                  "blue") ||
                                "green"
                              }
                            >
                              {
                                ControlUnitState[
                                  deviceStatus?.state ?? ControlUnitState.idle
                                ]
                              }
                            </Typography>
                            <Typography>
                              {deviceStatus?.updatedAt
                                ? `${new Date(
                                    deviceStatus?.updatedAt
                                  ).toLocaleDateString()} - ${new Date(
                                    deviceStatus?.updatedAt
                                  ).toLocaleTimeString()}`
                                : "Not seen"}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <ControlUnitImgStyle
                      alt={controlUnit.name}
                      src={mockImgControlUnit}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {startTimedOut && (
                      <Alert color={"error"}>Start timed out</Alert>
                    )}
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Button
                  color="success"
                  variant="contained"
                  startIcon={<Icon icon={playCircleFill} />}
                  onClick={() => {
                    setcontrolUnitParams({});
                    setOpenStartConfirmationModal(true);
                  }}
                  disabled={
                    (isStarting ||
                      started ||
                      deviceStatus?.state !== ControlUnitState.idle) &&
                    deviceStatus?.updatedAt != null
                  }
                >
                  {isStarting ? "Starting" : started ? "Started" : "Start"}
                </Button>
                <Button
                  color="error"
                  variant="contained"
                  startIcon={<Icon icon={stopCircleFill} />}
                  onClick={() => stopControlUnit()}
                  disabled={!(isStarting || started)}
                >
                  Stop
                </Button>
                <Button
                  color="info"
                  variant="contained"
                  startIcon={<Icon icon={stopCircleFill} />}
                  onClick={() => getControlUnitConfig()}
                >
                  Get config
                </Button>
              </CardActions>
            </Card>
            {latestControlUnitCycles?.get(controlUnit.deviceForeignId) && (
              <Card>
                <CardHeader />
                <CardContent>
                  <CycleDetails
                    existingCycle={latestControlUnitCycles?.get(
                      controlUnit.deviceForeignId
                    )}
                    dataPoints={dataPoints?.get(
                      latestControlUnitCycles?.get(controlUnit.deviceForeignId)
                        ?.id ?? ""
                    )}
                  />
                </CardContent>
              </Card>
            )}

            <Card>
              <CardContent>
                <Accordion>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Stack
                      spacing={4}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography> Run autotuning test </Typography>
                    </Stack>
                  </AccordionSummary>
                  <AccordionDetails>
                    <RunTestForm
                      formModel={controlUnitParams}
                      SendStartCommand={function (
                        params: ControlUnitParamsDto
                      ): void {
                        // throw new Error("Function not implemented.");
                        setcontrolUnitParams(params);
                        setOpenStartConfirmationModal(true);
                      }}
                      deviceRunning={false}
                      startDisabled={
                        (isStarting ||
                          started ||
                          deviceStatus?.state !== ControlUnitState.idle) &&
                        deviceStatus?.updatedAt != null
                      }
                    />
                  </AccordionDetails>
                </Accordion>
              </CardContent>
            </Card>
            <RunsListCard controlUnitId={controlUnit.deviceForeignId} />
          </Stack>
        </Container>
      )}
      <Modal
        open={openStartConfirmationModal}
        onClose={() => setOpenStartConfirmationModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack direction={"column"} spacing={4}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Please confirm that the device is safe to run before starting!
            </Typography>
            <TextField
              value={startCycles}
              type={"number"}
              label="Number of Cycles"
              onChange={(e) => {
                const target = e.target as HTMLInputElement;
                setStartCycles(target.value);
              }}
            />
            <LoadingButton
              color="success"
              variant="contained"
              startIcon={<Icon icon={playCircleFill} />}
              onClick={() => {
                const data: ControlUnitParamsDto = {};
                if (!isNaN(Number(startCycles))) {
                  data.cycles = Number(startCycles);
                }
                startControlUnit(data);
              }}
              loading={isStarting}
              disabled={isStarting || started}
            >
              {isStarting ? "Starting" : "Start run"}
            </LoadingButton>
            {startFailed && (
              <Alert severity="error">
                <AlertTitle>
                  Start command failed because of reason: {failureReason}
                </AlertTitle>
              </Alert>
            )}
          </Stack>
        </Box>
      </Modal>
      <ControlUnitConfigDialog
        config={config}
        open={openConfigDialog}
        handleClose={() => setOpenConfigDialog(false)}
      />
    </Page>
  );
};
export default ControlUnitPage;
