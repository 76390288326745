import { Icon } from "@iconify/react";
import React, { useCallback, useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";

import plusFill from "@iconify/icons-eva/plus-fill";
// material
import {
  Stack,
  Button,
  Container,
  Typography,
  Snackbar,
  Alert,
  AlertColor,
} from "@mui/material";
// components
import Page from "../../components/Page";

import { TestRun } from "../../Models/TestRun";
import { GetVcApiClient } from "../../utils/HttpClient";
import { useMsal } from "@azure/msal-react";
import TestRunsTableCard from "../../components/TestsRuns/TestRunsTableCard";

// ----------------------------------------------------------------------

const TestRunsPage: React.FC = () => {
  const { instance } = useMsal();
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [shouldReload, setShouldReload] = useState(true);
  const [snackbarMessage, setSnackbarMessage] = useState<string | undefined>(
    undefined
  );
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    AlertColor | undefined
  >(undefined);

  const [testRuns, setTestRuns] = useState<ReadonlyArray<TestRun>>([]);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
    setSnackbarMessage(undefined);
    setSnackbarSeverity(undefined);
  };

  const getTestRuns = useCallback(async () => {
    if (shouldReload) {
      const axiosClient = await GetVcApiClient(instance);
      if (axiosClient) {
        axiosClient.get<ReadonlyArray<TestRun>>(`test-runs`).then((value) => {
          setTestRuns(value.data);
        });
      }
      setShouldReload(false);
    }
  }, [instance, shouldReload]);

  useEffect(() => {
    getTestRuns();
  }, [getTestRuns]);

  return (
    <Page title="Test runs">
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Test runs
          </Typography>
          <Button
            variant="contained"
            component={RouterLink}
            to="new"
            startIcon={<Icon icon={plusFill} />}
          >
            New test run
          </Button>
        </Stack>
        <TestRunsTableCard
          testRuns={testRuns}
          setShouldReload={setShouldReload}
        />
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbarSeverity}
          sx={{ width: "100%" }}
        >
          <Typography variant="body1">{snackbarMessage}</Typography>
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default TestRunsPage;
