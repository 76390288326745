import { Autocomplete, TextField } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { Run } from "../../Models/DeviceData/Run";
import { GetVcApiClient } from "../../utils/HttpClient";

const RunsSelect: React.FC<{
  deviceId: string;
  onSelectedRun: (newRunId: string) => void;
}> = ({ deviceId, onSelectedRun }) => {
  const { instance } = useMsal();
  const [selectedRunId, setSelectedRunId] = useState<string | null>(null);
  const [runs, setRuns] = useState<ReadonlyArray<Run>>([]);

  const getRuns = useCallback(async () => {
    const axiosClient = await GetVcApiClient(instance);
    if (axiosClient) {
      axiosClient
        .get<ReadonlyArray<Run>>(`device-data/${deviceId}/runs`)
        .then((value) => {
          setRuns(value.data);
        });
    }
  }, [instance, deviceId]);

  useEffect(() => {
    getRuns();
  }, [getRuns]);

  let hasRunInList = runs.find((value) => value.id === selectedRunId);

  return (
    <Autocomplete
      id="runs-select"
      options={runs}
      disableCloseOnSelect
      getOptionLabel={(option: Run) =>
        `${new Date(option.time).toLocaleDateString()} - ${new Date(
          option.time
        ).toLocaleTimeString()}`
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          {`${new Date(option.time).toLocaleDateString()} - ${new Date(
            option.time
          ).toLocaleTimeString()}`}
        </li>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} label="Runs" placeholder="Runs" />
      )}
      value={
        hasRunInList ? runs.find((value1) => value1.id === selectedRunId) : null
      }
      onChange={(event, newValue) => {
        setSelectedRunId(newValue?.id ?? null);
        onSelectedRun(newValue?.id ?? "");
      }}
    />
  );
};
export default RunsSelect;
