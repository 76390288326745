import { Configuration, RedirectRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID
      ? process.env.REACT_APP_CLIENT_ID
      : "2402f03e-b073-4475-acfc-61248b1123c0",
    authority:
      "https://login.microsoftonline.com/bb978653-50bb-42d4-989d-d2d93cfd0d84",
    redirectUri: process.env.REACT_APP_REDIRECT_URL
      ? process.env.REACT_APP_REDIRECT_URL
      : "http://localhost:3000",
    postLogoutRedirectUri: process.env.REACT_APP_LOGOUT_REDIRECT_URL
      ? process.env.REACT_APP_LOGOUT_REDIRECT_URL
      : "http://localhost:3000",
  },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: RedirectRequest = {
  scopes: [
    "api://2402f03e-b073-4475-acfc-61248b1123c0/access_as_user",
    "email",
    "openid",
    "profile",
    "User.Read",
  ],
};
export const apiTokenRequest: RedirectRequest = {
  scopes: [
    "api://2402f03e-b073-4475-acfc-61248b1123c0/access_as_user",
    "email",
    "openid",
    "profile",
  ],
};
export const msGraphRequest: RedirectRequest = {
  scopes: ["User.Read"],
};
