import { ControlUnitStatus } from "../Models/ControlUnitStatus";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import { apiConfig } from "../apiConfig";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { apiTokenRequest } from "../authConfig";

export interface StatusesContextProps {
  statuses: Map<string, ControlUnitStatus>;
}

export const StatusesContext = React.createContext<
  Partial<StatusesContextProps>
>({
  statuses: new Map<string, ControlUnitStatus>(),
});

export const StatusesProvider = ({ children }) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [connection, setConnection] = useState<HubConnection | null>(null);
  const [statuses, setStatuses] = useState(
    new Map<string, ControlUnitStatus>()
  );

  const statusesRef = useRef(new Map<string, ControlUnitStatus>());

  const connectToHub = useCallback(async () => {
    if (isAuthenticated && !connection) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${apiConfig.deviceApiUrl}/hubs/status`, {
          accessTokenFactory: async () => {
            const account = instance.getActiveAccount();
            if (account) {
              let response = await instance.acquireTokenSilent({
                ...apiTokenRequest,
                account: account,
              });
              return response.accessToken;
            }
            return "";
          },
        })
        .withAutomaticReconnect()
        .build();
      setConnection(newConnection);
    }
  }, [isAuthenticated, instance, connection]);

  useEffect(() => {
    connectToHub();
  }, [connectToHub]);

  useEffect(() => {
    if (connection && connection.state === HubConnectionState.Disconnected) {
      connection
        .start()
        .then((result) => {
          console.log("Connected to status hub!");

          connection.on("ReceiveStatus", (status: ControlUnitStatus) => {
            statusesRef.current.set(status.deviceId, status);
            setStatuses(
              new Map<string, ControlUnitStatus>(statusesRef.current)
            );
          });
        })
        .catch((e) => {
          console.log("Connection failed: ", e);
          setConnection(null);
        });
    }
    return function cleanup() {
      if (connection && connection.state !== HubConnectionState.Disconnected) {
        connection.stop();
      }
    };
  }, [connection]);

  return (
    <StatusesContext.Provider value={{ statuses: statuses }}>
      {children}
    </StatusesContext.Provider>
  );
};
