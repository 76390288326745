import ReactApexChart from "react-apexcharts";
// material
import { Box, Card, CardHeader } from "@mui/material";

const VacuumGraph: React.FC<{
  vacuumSensorValues: Map<number, number>;
  height?: number;
}> = ({ vacuumSensorValues, height }) => {
  return (
    <Card>
      <CardHeader title="Vacuum" />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={[
            {
              name: "Vacuum",
              data: Array.from(vacuumSensorValues).map((value) => {
                return { x: value[0] / 1000, y: value[1] };
              }),
            },
          ]}
          options={{
            xaxis: {
              type: "numeric",
              title: { text: "Seconds" },
              min: 0,
              max: 10,
            },
            yaxis: {
              min: 0,
              title: { text: "Vacuum" },
            },
          }}
          height={height ?? 200}
        />
      </Box>
    </Card>
  );
};

export default VacuumGraph;
