import { Icon } from "@iconify/react";
import pieChart2Fill from "@iconify/icons-eva/pie-chart-2-fill";
import clipboardFill from "@iconify/icons-eva/clipboard-fill";
import inboxFill from "@iconify/icons-eva/inbox-fill";
import hardDriveFill from "@iconify/icons-eva/hard-drive-fill";

// ----------------------------------------------------------------------

const getIcon = (name) => <Icon icon={name} width={22} height={22} />;

const sidebarConfig = [
  {
    title: "dashboard",
    path: "/dashboard/app",
    icon: getIcon(pieChart2Fill),
  },
  {
    title: "control units",
    path: "/dashboard/control-units",
    icon: getIcon(hardDriveFill),
  },
  {
    title: "tests",
    path: "/dashboard/tests",
    icon: getIcon(inboxFill),
  },
  {
    title: "test runs",
    path: "/dashboard/test-runs",
    icon: getIcon(clipboardFill),
  },
];

export default sidebarConfig;
