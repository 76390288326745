import React, { useCallback, useEffect, useState } from "react";
import { Button, IconButton } from "@mui/material";
import { Icon } from "@iconify/react";
import plusCircleFill from "@iconify/icons-eva/plus-circle-fill";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useMsal } from "@azure/msal-react";
import { GetVcApiClient } from "../../utils/HttpClient";
import { TestRun } from "../../Models/TestRun";
import TestRunsSelectTable from "./TestRunsSelectTable";

const AddTestRunButton: React.FC<{
  testId: string;
  onAddedTestRuns: () => void;
}> = ({ testId, onAddedTestRuns }) => {
  const { instance } = useMsal();
  const [open, setOpen] = useState(false);
  const [testRuns, setTestRuns] = useState<ReadonlyArray<TestRun>>([]);
  const [selectedTestRunIds, setSelectedTestRunIds] = useState<
    ReadonlyArray<string>
  >([]);

  const getTestRuns = useCallback(async () => {
    const axiosClient = await GetVcApiClient(instance);
    if (axiosClient) {
      axiosClient.get<ReadonlyArray<TestRun>>(`test-runs`).then((value) => {
        setTestRuns(value.data);
      });
    }
  }, [instance]);

  useEffect(() => {
    getTestRuns();
  }, [getTestRuns]);

  const addTestRunsToTest = async (testRunIds: ReadonlyArray<string>) => {
    const axiosClient = await GetVcApiClient(instance);
    if (axiosClient) {
      console.log(testRunIds);
      axiosClient.put(`tests/${testId}/test-runs`, testRunIds).then(() => {
        onAddedTestRuns();
        setOpen(false);
      });
    }
  };

  return (
    <>
      <IconButton color={"success"} onClick={() => setOpen(true)}>
        <Icon icon={plusCircleFill} />
      </IconButton>
      <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Add test runs to test</DialogTitle>
        <DialogContent>
          <TestRunsSelectTable
            testRuns={testRuns}
            onSelectedTestRuns={(testRunIds) => {
              console.log(testRunIds);
              setSelectedTestRunIds(testRunIds);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Button
            onClick={() => {
              addTestRunsToTest(selectedTestRunIds);
            }}
          >
            Add Test Runs
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddTestRunButton;
