import React, { useEffect, useCallback, useState } from "react";
import axios, { AxiosRequestHeaders } from "axios";
import { apiConfig } from "../apiConfig";
import { msGraphRequest } from "../authConfig";
import { useMsal } from "@azure/msal-react";

export type GraphData = {
  displayName: string;
  jobTitle: string;
  mail: string;
  businessPhones: string[];
  officeLocation: string;
};

export type AccountContextProps = {
  graphData: GraphData | null;
};

export const AccountContext = React.createContext<Partial<AccountContextProps>>(
  {}
);
export const AccountProvider = ({ children }) => {
  const { instance } = useMsal();
  const [graphData, setGraphData] = useState<GraphData | null>(null);
  const [hasFetched, setHasFetched] = useState(false);
  const account = instance.getActiveAccount();

  const getAccount = useCallback(async () => {
    if (!hasFetched && account) {
      setHasFetched(true);

      let response = await instance.acquireTokenSilent({
        ...msGraphRequest,
        account: account,
      });

      const headers: AxiosRequestHeaders = {
        Authorization: "Bearer " + response.accessToken,
      };

      const axiosClient = axios.create({
        baseURL: apiConfig.graphEndpoint,
        headers,
      });

      axiosClient
        .get<GraphData>("/me")
        .then((response) => {
          setGraphData(response.data);
        })
        .catch((reason) => {
          console.error(reason);
        });
    }
  }, [hasFetched, account, instance]);

  useEffect(() => {
    getAccount();
  }, [getAccount]);

  return (
    <AccountContext.Provider value={{ graphData: graphData }}>
      {children}
    </AccountContext.Provider>
  );
};
