import React, { useState } from "react";
import Scrollbar from "../Scrollbar";
import {
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import TestsListHead from "./TestsListHead";
import SearchNotFound from "../SearchNotFound";
import { TestRun } from "../../Models/TestRun";
import { filter } from "lodash";

const TABLE_HEAD = [
  { id: "material", label: "Material", alignRight: false },
  { id: "filterType", label: "Filter Type", alignRight: false },
  { id: "conveyor", label: "Conveyor", alignRight: false },
  { id: "createdBy", label: "Created By", alignRight: false },
  { id: "createdAt", label: "Created At", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (test: TestRun) =>
        test.id.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

const TestRunsSelectTable: React.FC<{
  testRuns: ReadonlyArray<TestRun>;
  onSelectedTestRuns: (testRunIds: ReadonlyArray<string>) => void;
}> = ({ testRuns, onSelectedTestRuns }) => {
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [selected, setSelected] = useState<Array<string>>([]);
  const [orderBy, setOrderBy] = useState("email");
  const [filterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = testRuns.map((n) => n.id);
      setSelected(newSelecteds as Array<string>);
      onSelectedTestRuns(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const onSelected = (event, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: Array<string> = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
    onSelectedTestRuns(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - testRuns.length) : 0;

  const filteredTests = applySortFilter(
    testRuns,
    getComparator(order, orderBy),
    filterName
  );
  const isTestNotFound = filteredTests.length === 0;

  return (
    <>
      <Scrollbar sx={{}}>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TestsListHead
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={testRuns.length}
              numSelected={selected.length}
              onRequestSort={handleRequestSort}
              onSelectAllClick={handleSelectAllClick}
            />
            <TableBody>
              {testRuns
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((testRun) => {
                  const isItemSelected = selected.indexOf(testRun.id) !== -1;
                  return (
                    <TableRow
                      hover
                      key={testRun.id}
                      tabIndex={-1}
                      role="checkbox"
                      selected={isItemSelected}
                      aria-checked={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          onChange={(event) => onSelected(event, testRun.id)}
                        />
                      </TableCell>
                      <TableCell align="left">
                        {testRun.material?.name
                          ? testRun.material.name
                          : "No Material"}
                      </TableCell>
                      <TableCell align="left">
                        {testRun.conveyor?.filterType}
                      </TableCell>
                      <TableCell align="left">
                        {testRun.conveyor?.code}
                      </TableCell>
                      <TableCell align="left">
                        {testRun.createdBy?.name}
                      </TableCell>
                      <TableCell align="left">
                        {new Date(testRun.createdAt).toLocaleDateString()} -{" "}
                        {new Date(testRun.createdAt).toLocaleTimeString()}
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: 53 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            {isTestNotFound && (
              <TableBody>
                <TableRow>
                  <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                    <SearchNotFound searchQuery={filterName} />
                  </TableCell>
                </TableRow>
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={testRuns.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
export default TestRunsSelectTable;
