import React from "react";
import { Form } from "formik";
import { Stack, TextField } from "@mui/material";
import {
  FieldInputProps,
  FormikErrors,
  FormikTouched,
} from "formik/dist/types";
import { TestRunFormModel } from "../../Pages/TestRuns/CreateTestRunPage";

const CreateTestRunFormFields: React.FC<{
  getFieldProps: (nameOrOptions: any) => FieldInputProps<any>;
  errors: FormikErrors<TestRunFormModel>;
  touched: FormikTouched<TestRunFormModel>;
  handleSubmit: () => void;
}> = ({ getFieldProps, errors, touched, handleSubmit }) => {
  return (
    <Form autoComplete="off" onSubmit={handleSubmit}>
      <Stack spacing={3}>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            fullWidth
            type={"text"}
            label="Name"
            error={Boolean(touched.name && errors.name)}
            {...getFieldProps("name")}
            helperText={touched.name && errors.name}
          />
          <TextField
            fullWidth
            type={"text"}
            label="Material"
            error={Boolean(touched.materialName && errors.materialName)}
            {...getFieldProps("materialName")}
            helperText={touched.materialName && errors.materialName}
          />
          <TextField
            fullWidth
            type={"text"}
            label="Conveyor dimension"
            {...getFieldProps("conveyorDim")}
            error={Boolean(touched.conveyorDim && errors.conveyorDim)}
            helperText={touched.conveyorDim && errors.conveyorDim}
          />
          <TextField
            fullWidth
            label="Sensing material side position"
            {...getFieldProps("sensingMaterialSidePosition")}
            error={Boolean(
              touched.sensingMaterialSidePosition &&
                errors.sensingMaterialSidePosition
            )}
            helperText={
              touched.sensingMaterialSidePosition &&
              errors.sensingMaterialSidePosition
            }
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            fullWidth
            label="Filter type"
            {...getFieldProps("filterType")}
            error={Boolean(touched.filterType && errors.filterType)}
            helperText={touched.filterType && errors.filterType}
          />
          <TextField
            fullWidth
            type={"text"}
            label="Filter size"
            {...getFieldProps("filterSize")}
            error={Boolean(touched.filterSize && errors.filterSize)}
            helperText={touched.filterSize && errors.filterSize}
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            fullWidth
            type={"number"}
            label="Pump size"
            {...getFieldProps("pumpSize")}
            error={Boolean(touched.pumpSize && errors.pumpSize)}
            helperText={touched.pumpSize && errors.pumpSize}
          />
        </Stack>
        <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
          <TextField
            fullWidth
            label="Note"
            {...getFieldProps("note")}
            error={Boolean(touched.note && errors.note)}
            helperText={touched.note && errors.note}
          />
        </Stack>
      </Stack>
    </Form>
  );
};

export default CreateTestRunFormFields;
