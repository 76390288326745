import { Navigate, useRoutes } from "react-router-dom";
// Layouts
import DashboardLayout from "./Layouts/dashboard";
import LogoOnlyLayout from "./Layouts/LogoOnlyLayout";
//

import DashboardApp from "./Pages/DashboardApp";
import NotFound from "./Pages/Page404";
import ControlUnitsPage from "./Pages/ControlUnits/ControlUnitsPage";
import ControlUnitPage from "./Pages/ControlUnits/ControlUnitPage";
import EmptyLayout from "./Layouts/EmptyLayout";
import TestRunsPage from "./Pages/TestRuns/TestRunsPage";
import TestsPage from "./Pages/Tests/TestsPage";
import TestRunPage from "./Pages/TestRuns/TestRunPage";
import CreateTestRunPage from "./Pages/TestRuns/CreateTestRunPage";
import TestPage from "./Pages/Tests/TestPage";
import CreateTestPage from "./Pages/Tests/CreateTestPage";
import EditControlUnitPage from "./Pages/ControlUnits/EditControlUnitPage";
import Login from "./Pages/Login";
import RunPage from "./Pages/RunPage";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/dashboard",
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" replace /> },
        { path: "/dashboard", element: <Navigate to="/dashboard/app" /> },
        { path: "app", element: <DashboardApp /> },
        {
          path: "tests",
          element: <EmptyLayout />,
          children: [
            { path: "", element: <TestsPage /> },
            { path: ":id", element: <TestPage /> },
            { path: "new", element: <CreateTestPage /> },
          ],
        },
        {
          path: "runs/:id",
          element: <RunPage />,
        },
        {
          path: "test-runs",
          element: <EmptyLayout />,
          children: [
            { path: "", element: <TestRunsPage /> },
            { path: ":id", element: <TestRunPage /> },
            { path: "new", element: <CreateTestRunPage /> },
          ],
        },
        {
          path: "control-units",
          element: <EmptyLayout />,
          children: [
            { path: "", element: <ControlUnitsPage /> },
            { path: ":id", element: <ControlUnitPage /> },
            { path: ":id/edit", element: <EditControlUnitPage /> },
          ],
        },
      ],
    },
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/pages",
      element: <LogoOnlyLayout />,
      children: [
        { path: "404", element: <NotFound /> },
        { path: "/pages", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/pages/404" replace /> },
  ]);
}
