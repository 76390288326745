import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  HubConnection,
  HubConnectionBuilder,
  HubConnectionState,
} from "@microsoft/signalr";
import { apiConfig } from "../apiConfig";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { apiTokenRequest } from "../authConfig";
import { TestStart } from "../Models/DeviceData/TestStart";
import { TestResults } from "../Models/DeviceData/TestResults";
import { Cycle } from "../Models/DeviceData/Cycle";
import { DataPoint } from "../Models/DeviceData/DataPoint";

export interface DeviceDataContextProps {
  runStarts: Map<string, TestStart>;
  runResults: Map<string, TestResults>;
  cycles: Map<string, Cycle>;
  latestControlUnitCycles: Map<string, Cycle>;
  dataPoints: Map<string, ReadonlyArray<DataPoint>>;
}

export const DeviceDataContext = React.createContext<
  Partial<DeviceDataContextProps>
>({
  runStarts: new Map<string, TestStart>(),
  runResults: new Map<string, TestResults>(),
  cycles: new Map<string, Cycle>(),
  latestControlUnitCycles: new Map<string, Cycle>(),
  dataPoints: new Map<string, ReadonlyArray<DataPoint>>(),
});

export const DeviceDataProvider = ({ children }) => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const [connection, setConnection] = useState<HubConnection | null>(null);

  const [runStarts, setRunStarts] = useState(new Map<string, TestStart>());

  const [runResults, setRunResults] = useState(new Map<string, TestResults>());
  const [cycles, setCycles] = useState(new Map<string, Cycle>());
  const [latestControlUnitCycles, setLatestControlUnitCycles] = useState(
    new Map<string, Cycle>()
  );
  const [dataPoints, setDataPoints] = useState(
    new Map<string, ReadonlyArray<DataPoint>>()
  );

  const runStartsRef = useRef(new Map<string, TestStart>());
  const runResultsRef = useRef(new Map<string, TestResults>());
  const cyclesRef = useRef(new Map<string, Cycle>());
  const latestControlUnitCyclesRef = useRef(new Map<string, Cycle>());
  const dataPointsRef = useRef(new Map<string, ReadonlyArray<DataPoint>>());

  const connectToHub = useCallback(async () => {
    if (isAuthenticated && !connection) {
      const newConnection = new HubConnectionBuilder()
        .withUrl(`${apiConfig.deviceApiUrl}/hubs/device-data`, {
          accessTokenFactory: async () => {
            const account = instance.getActiveAccount();
            if (account) {
              let response = await instance.acquireTokenSilent({
                ...apiTokenRequest,
                account: account,
              });
              return response.accessToken;
            }
            return "";
          },
        })
        .withAutomaticReconnect()
        .build();
      setConnection(newConnection);
    }
  }, [isAuthenticated, instance, connection]);

  useEffect(() => {
    connectToHub();
  }, [connectToHub]);

  useEffect(() => {
    if (connection && connection.state === HubConnectionState.Disconnected) {
      connection
        .start()
        .then((result) => {
          console.log("Connected to device data hub!");

          connection.on("ReceiveTestStart", (testStart: TestStart) => {
            runStartsRef.current.set(testStart.deviceId, testStart);
            setRunStarts(new Map<string, TestStart>(runStartsRef.current));
          });

          connection.on("ReceiveTestResults", (testResults: TestResults) => {
            runResultsRef.current.set(testResults.testId, testResults);
            setRunResults(new Map<string, TestResults>(runResultsRef.current));
          });

          connection.on("ReceiveCycle", (cycle: Cycle) => {
            cyclesRef.current.set(cycle.id, cycle);
            setCycles(new Map<string, Cycle>(cyclesRef.current));

            latestControlUnitCyclesRef.current.set(cycle.deviceId, cycle);
            setLatestControlUnitCycles(
              new Map<string, Cycle>(latestControlUnitCyclesRef.current)
            );
          });

          connection.on(
            "ReceiveDataPoints",
            (dataPoints: ReadonlyArray<DataPoint>) => {
              let cycleId = dataPoints[0].cycleId;
              let currentDataPoints = dataPointsRef.current.get(cycleId);
              if (!currentDataPoints) {
                dataPointsRef.current.set(cycleId, dataPoints);
              } else {
                dataPointsRef.current.set(cycleId, [
                  ...currentDataPoints,
                  ...dataPoints,
                ]);
              }

              setDataPoints(
                new Map<string, ReadonlyArray<DataPoint>>(dataPointsRef.current)
              );
            }
          );
        })
        .catch((e) => {
          console.log("Connection failed: ", e);
          setConnection(null);
        });
    }
    return function cleanup() {
      if (connection && connection.state !== HubConnectionState.Disconnected) {
        connection.stop();
      }
    };
  }, [connection]);

  return (
    <DeviceDataContext.Provider
      value={{
        runStarts: runStarts,
        runResults: runResults,
        cycles: cycles,
        latestControlUnitCycles: latestControlUnitCycles,
        dataPoints: dataPoints,
      }}
    >
      {children}
    </DeviceDataContext.Provider>
  );
};
