import React, { useCallback, useEffect, useState } from "react";
import { TestRun } from "../../Models/TestRun";
import { Autocomplete, Checkbox, TextField } from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useMsal } from "@azure/msal-react";
import { GetVcApiClient } from "../../utils/HttpClient";

const TestRunsSelect: React.FC<{
  selectedTestRunIds: string[];
  onSelectedTestRun: (newTestRunIds: string[]) => void;
  disabled: boolean;
}> = ({ selectedTestRunIds, onSelectedTestRun, disabled }) => {
  const { instance } = useMsal();
  const [testRuns, setTestRun] = useState<ReadonlyArray<TestRun>>([]);

  const getTestRun = useCallback(async () => {
    const axiosClient = await GetVcApiClient(instance);
    if (axiosClient) {
      axiosClient.get<ReadonlyArray<TestRun>>(`test-runs`).then((value) => {
        setTestRun(value.data);
      });
    }
  }, [instance]);

  useEffect(() => {
    getTestRun();
  }, [getTestRun]);
  return (
    <Autocomplete
      multiple
      id="test-runs-select"
      options={testRuns}
      disableCloseOnSelect
      getOptionLabel={(option: TestRun) =>
        `${option.createdBy?.name} ${new Date(
          option.createdAt
        ).toLocaleDateString()}`
      }
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <Checkbox
            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
            checkedIcon={<CheckBoxIcon fontSize="small" />}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {`${option.createdBy?.name} ${new Date(
            option.createdAt
          ).toLocaleDateString()}`}
        </li>
      )}
      style={{ width: 500 }}
      renderInput={(params) => (
        <TextField {...params} label="Test runs" placeholder="Test run" />
      )}
      value={
        selectedTestRunIds
          .map((value) => testRuns.find((value1) => value1.id === value))
          .filter((value) => value !== undefined) as TestRun[]
      }
      onChange={(event, newValue) => {
        onSelectedTestRun(newValue.map((value) => value.id));
      }}
      disabled={disabled}
    />
  );
};
export default TestRunsSelect;
