import { Icon } from "@iconify/react";
import * as Yup from "yup";
import React, { useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";

// material
import { Stack, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { ControlUnitParamsDto } from "../../Models/Dto/StartCommandDto";
import playCircleFill from "@iconify/icons-eva/play-circle-fill";

// ----------------------------------------------------------------------

interface FormModel {
  test_name: string;
  cycles: string | number;
  vacuum_safety: string | number;
  vacuum_safety_interval: string | number;
  pump_pressure: string | number;
  change_step_evaluation: string | number;
  change_step_calibration: string | number;
  time_delay: string | number;
  average_offset: string | number;
  valve_pos_max: string | number;
  evaluation_count: string | number;
  time_fill_improvement: string | number;
  calibration_charge_time: string | number;
  charge_time: string | number;
  discharge_time: string | number;
  valve_open_delay: string | number;
  pump_start_delay: string | number;
  shock_cycles: string | number;
  pipe_emptying_time: string | number;
  start_valve_pos: string | number;
  discharge_high_low_diff_pct: string | number;
  level_detector_delay_charge: string | number;
  level_detector_delay_discharge: string | number;
  sensor_cleaning: string;
  sensor_cleaning_interval: string | number;
  sensor_cleaning_time: string | number;
  blockage_interval: string | number;
  vibrator: string;
  fluid_conveyor: string;
  discharge_opt: string;
  vacuum_diff_level: string | number;
  func: string;
}

const toFormModel = (model: ControlUnitParamsDto): FormModel => {
  return {
    test_name: model.testName ?? "semi-auto-test",
    cycles: model.cycles ?? 1,
    vacuum_safety: model.vacuumSafety ?? 65,
    vacuum_safety_interval: model.vacuumSafetyInterval ?? 1,
    pump_pressure: model.pumpPressure ?? 6,
    change_step_evaluation: model.changeStepEvaluation ?? 2,
    change_step_calibration: model.changeStepCalibration ?? 8,
    time_delay: model.timeDelay ?? 4,
    average_offset: model.averageOffset ?? 2,
    valve_pos_max: model.valvePosMax ?? 99,
    evaluation_count: model.evaluationCount ?? 1,
    time_fill_improvement: model.timeFillImprovement ?? 10,
    calibration_charge_time: model.calibrationChargeTime ?? 90,
    charge_time: model.chargeTime ?? 60,
    discharge_time: model.dischargeTime ?? 4,
    valve_open_delay: model.valveOpenDelay ?? 1,
    pump_start_delay: model.pumpStartDelay ?? 1,
    shock_cycles: model.shockCycles ?? 1,
    pipe_emptying_time: model.pipeEmptyingTime ?? 0,
    start_valve_pos: model.startValvePos ?? 50,
    discharge_high_low_diff_pct: model.dischargeHighLowDiffPct ?? 51,
    level_detector_delay_charge: model.levelDetectorDelayCharge ?? 0.1,
    level_detector_delay_discharge: model.levelDetectorDelayDischarge ?? 0.5,
    sensor_cleaning: model.sensorCleaning ?? "auto",
    sensor_cleaning_interval: model.sensorCleaningInterval ?? 0.4,
    sensor_cleaning_time: model.sensorCleaningTime ?? 0.2,
    blockage_interval: model.blockageInterval ?? 2,
    vibrator: model.vibrator ?? "off",
    fluid_conveyor: model.fluidConveyor ?? "enabled",
    discharge_opt: model.dischargeOpt ?? "enabled",
    vacuum_diff_level: model.vacuumDiffLevel ?? 0,
    func: model.func ?? "semi",
  };
};

const toDto = (model: FormModel): ControlUnitParamsDto => {
  return {
    testName: model.test_name === "" ? undefined : model.test_name,
    cycles: model.cycles === "" ? undefined : (model.cycles as number),
    vacuumSafety:
      model.vacuum_safety === "" ? undefined : (model.vacuum_safety as number),
    vacuumSafetyInterval:
      model.vacuum_safety_interval === ""
        ? undefined
        : (model.vacuum_safety_interval as number),
    pumpPressure:
      model.pump_pressure === "" ? undefined : (model.pump_pressure as number),
    changeStepEvaluation:
      model.change_step_evaluation === ""
        ? undefined
        : (model.change_step_evaluation as number),
    changeStepCalibration:
      model.change_step_calibration === ""
        ? undefined
        : (model.change_step_calibration as number),
    timeDelay:
      model.time_delay === "" ? undefined : (model.time_delay as number),
    averageOffset:
      model.average_offset === ""
        ? undefined
        : (model.average_offset as number),
    valvePosMax:
      model.valve_pos_max === "" ? undefined : (model.valve_pos_max as number),
    evaluationCount:
      model.evaluation_count === ""
        ? undefined
        : (model.evaluation_count as number),
    timeFillImprovement:
      model.time_fill_improvement === ""
        ? undefined
        : (model.time_fill_improvement as number),
    calibrationChargeTime:
      model.calibration_charge_time === ""
        ? undefined
        : (model.calibration_charge_time as number),
    chargeTime:
      model.charge_time === "" ? undefined : (model.charge_time as number),
    dischargeTime:
      model.discharge_time === ""
        ? undefined
        : (model.discharge_time as number),
    valveOpenDelay:
      model.valve_open_delay === ""
        ? undefined
        : (model.valve_open_delay as number),
    pumpStartDelay:
      model.pump_start_delay === ""
        ? undefined
        : (model.pump_start_delay as number),
    shockCycles:
      model.shock_cycles === "" ? undefined : (model.shock_cycles as number),
    pipeEmptyingTime:
      model.pipe_emptying_time === ""
        ? undefined
        : (model.pipe_emptying_time as number),
    startValvePos:
      model.start_valve_pos === ""
        ? undefined
        : (model.start_valve_pos as number),
    dischargeHighLowDiffPct:
      model.discharge_high_low_diff_pct === ""
        ? undefined
        : (model.discharge_high_low_diff_pct as number),
    levelDetectorDelayCharge:
      model.level_detector_delay_charge === ""
        ? undefined
        : (model.level_detector_delay_charge as number),
    levelDetectorDelayDischarge:
      model.level_detector_delay_discharge === ""
        ? undefined
        : (model.level_detector_delay_discharge as number),
    sensorCleaning:
      model.sensor_cleaning === "" ? undefined : model.sensor_cleaning,
    sensorCleaningInterval:
      model.sensor_cleaning_interval === ""
        ? undefined
        : (model.sensor_cleaning_interval as number),
    sensorCleaningTime:
      model.sensor_cleaning_time === ""
        ? undefined
        : (model.sensor_cleaning_time as number),
    blockageInterval:
      model.blockage_interval === ""
        ? undefined
        : (model.blockage_interval as number),
    vibrator: model.vibrator === "" ? undefined : model.vibrator,
    fluidConveyor:
      model.fluid_conveyor === "" ? undefined : model.fluid_conveyor,
    dischargeOpt: model.discharge_opt === "" ? undefined : model.discharge_opt,
    vacuumDiffLevel:
      model.vacuum_diff_level === ""
        ? undefined
        : (model.vacuum_diff_level as number),
    func: model.func === "" ? undefined : model.func,
  };
};

const RunTestForm: React.FC<{
  formModel: ControlUnitParamsDto;
  SendStartCommand: (params: ControlUnitParamsDto) => void;
  deviceRunning: boolean;
  startDisabled: boolean;
}> = ({ formModel, SendStartCommand, deviceRunning, startDisabled }) => {
  const RegisterSchema = Yup.object().shape({
    test_name: Yup.string(),
    cycles: Yup.number(),
    vacuum_safety: Yup.number(),
    vacuum_safety_interval: Yup.number(),
    pump_pressure: Yup.number(),
    change_step_evaluation: Yup.number(),
    change_step_calibration: Yup.number(),
    time_delay: Yup.number(),
    average_offset: Yup.number(),
    valve_pos_max: Yup.number(),
    evaluation_count: Yup.number(),
    time_fill_improvement: Yup.number(),
    calibration_charge_time: Yup.number(),
    charge_time: Yup.number(),
    discharge_time: Yup.number(),
    valve_open_delay: Yup.number(),
    pump_start_delay: Yup.number(),
    shock_cycles: Yup.number(),
    pipe_emptying_time: Yup.number(),
    start_valve_pos: Yup.number(),
    discharge_high_low_diff_pct: Yup.number(),
    level_detector_delay_charge: Yup.number(),
    level_detector_delay_discharge: Yup.number(),
    sensor_cleaning: Yup.string(),
    sensor_cleaning_interval: Yup.number(),
    sensor_cleaning_time: Yup.number(),
    blockage_interval: Yup.number(),
    vibrator: Yup.string(),
    fluid_conveyor: Yup.string(),
    discharge_opt: Yup.string(),
    vacuum_diff_level: Yup.number(),
    func: Yup.string(),
  });

  const formik = useFormik({
    initialValues: toFormModel(formModel),
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      SendStartCommand(toDto(values));
    },
  });
  const {
    errors,
    resetForm,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
  } = formik;

  useEffect(() => {
    resetForm({ values: toFormModel(formModel) });
  }, [resetForm, formModel]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"string"}
              label="test_name"
              {...getFieldProps("test_name")}
              error={Boolean(touched.test_name && errors.test_name)}
              helperText={touched.test_name && errors.test_name}
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"string"}
              label="func"
              {...getFieldProps("func")}
              error={Boolean(touched.func && errors.func)}
              helperText={touched.func && errors.func}
              disabled={deviceRunning}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"number"}
              label="cycles"
              {...getFieldProps("cycles")}
              error={Boolean(touched.cycles && errors.cycles)}
              helperText={touched.cycles && errors.cycles}
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"number"}
              label="vacuum_safety"
              {...getFieldProps("vacuum_safety")}
              error={Boolean(touched.vacuum_safety && errors.vacuum_safety)}
              helperText={touched.vacuum_safety && errors.vacuum_safety}
              disabled={deviceRunning}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"number"}
              label="vacuum_safety_interval"
              {...getFieldProps("vacuum_safety_interval")}
              error={Boolean(
                touched.vacuum_safety_interval && errors.vacuum_safety_interval
              )}
              helperText={
                touched.vacuum_safety_interval && errors.vacuum_safety_interval
              }
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"number"}
              label="pump_pressure"
              {...getFieldProps("pump_pressure")}
              error={Boolean(touched.pump_pressure && errors.pump_pressure)}
              helperText={touched.pump_pressure && errors.pump_pressure}
              disabled={deviceRunning}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"number"}
              label="change_step_evaluation"
              {...getFieldProps("change_step_evaluation")}
              error={Boolean(
                touched.change_step_evaluation && errors.change_step_evaluation
              )}
              helperText={
                touched.change_step_evaluation && errors.change_step_evaluation
              }
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"number"}
              label="change_step_calibration"
              {...getFieldProps("change_step_calibration")}
              error={Boolean(
                touched.change_step_calibration &&
                  errors.change_step_calibration
              )}
              helperText={
                touched.change_step_calibration &&
                errors.change_step_calibration
              }
              disabled={deviceRunning}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"number"}
              label="time_delay"
              {...getFieldProps("time_delay")}
              error={Boolean(touched.time_delay && errors.time_delay)}
              helperText={touched.time_delay && errors.time_delay}
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"number"}
              label="average_offset"
              {...getFieldProps("average_offset")}
              error={Boolean(touched.average_offset && errors.average_offset)}
              helperText={touched.average_offset && errors.average_offset}
              disabled={deviceRunning}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"number"}
              label="valve_pos_max"
              {...getFieldProps("valve_pos_max")}
              error={Boolean(touched.valve_pos_max && errors.valve_pos_max)}
              helperText={touched.valve_pos_max && errors.valve_pos_max}
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"number"}
              label="evaluation_count"
              {...getFieldProps("evaluation_count")}
              error={Boolean(
                touched.evaluation_count && errors.evaluation_count
              )}
              helperText={touched.evaluation_count && errors.evaluation_count}
              disabled={deviceRunning}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"number"}
              label="time_fill_improvement"
              {...getFieldProps("time_fill_improvement")}
              error={Boolean(
                touched.time_fill_improvement && errors.time_fill_improvement
              )}
              helperText={
                touched.time_fill_improvement && errors.time_fill_improvement
              }
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"number"}
              label="calibration_charge_time"
              {...getFieldProps("calibration_charge_time")}
              error={Boolean(
                touched.calibration_charge_time &&
                  errors.calibration_charge_time
              )}
              helperText={
                touched.calibration_charge_time &&
                errors.calibration_charge_time
              }
              disabled={deviceRunning}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"number"}
              label="charge_time"
              {...getFieldProps("charge_time")}
              error={Boolean(touched.charge_time && errors.charge_time)}
              helperText={touched.charge_time && errors.charge_time}
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"number"}
              label="discharge_time"
              {...getFieldProps("discharge_time")}
              error={Boolean(touched.discharge_time && errors.discharge_time)}
              helperText={touched.discharge_time && errors.discharge_time}
              disabled={deviceRunning}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"number"}
              label="valve_open_delay"
              {...getFieldProps("valve_open_delay")}
              error={Boolean(
                touched.valve_open_delay && errors.valve_open_delay
              )}
              helperText={touched.valve_open_delay && errors.valve_open_delay}
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"number"}
              label="pump_start_delay"
              {...getFieldProps("pump_start_delay")}
              error={Boolean(
                touched.pump_start_delay && errors.pump_start_delay
              )}
              helperText={touched.pump_start_delay && errors.pump_start_delay}
              disabled={deviceRunning}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"number"}
              label="shock_cycles"
              {...getFieldProps("shock_cycles")}
              error={Boolean(touched.shock_cycles && errors.shock_cycles)}
              helperText={touched.shock_cycles && errors.shock_cycles}
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"number"}
              label="pipe_emptying_time"
              {...getFieldProps("pipe_emptying_time")}
              error={Boolean(
                touched.pipe_emptying_time && errors.pipe_emptying_time
              )}
              helperText={
                touched.pipe_emptying_time && errors.pipe_emptying_time
              }
              disabled={deviceRunning}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"number"}
              label="start_valve_pos"
              {...getFieldProps("start_valve_pos")}
              error={Boolean(touched.start_valve_pos && errors.start_valve_pos)}
              helperText={touched.start_valve_pos && errors.start_valve_pos}
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"number"}
              label="discharge_high_low_diff_pct"
              {...getFieldProps("discharge_high_low_diff_pct")}
              error={Boolean(
                touched.discharge_high_low_diff_pct &&
                  errors.discharge_high_low_diff_pct
              )}
              helperText={
                touched.discharge_high_low_diff_pct &&
                errors.discharge_high_low_diff_pct
              }
              disabled={deviceRunning}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"number"}
              label="level_detector_delay_charge"
              {...getFieldProps("level_detector_delay_charge")}
              error={Boolean(
                touched.level_detector_delay_charge &&
                  errors.level_detector_delay_charge
              )}
              helperText={
                touched.level_detector_delay_charge &&
                errors.level_detector_delay_charge
              }
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"number"}
              label="level_detector_delay_discharge"
              {...getFieldProps("level_detector_delay_discharge")}
              error={Boolean(
                touched.level_detector_delay_discharge &&
                  errors.level_detector_delay_discharge
              )}
              helperText={
                touched.level_detector_delay_discharge &&
                errors.level_detector_delay_discharge
              }
              disabled={deviceRunning}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"string"}
              label="sensor_cleaning"
              {...getFieldProps("sensor_cleaning")}
              error={Boolean(touched.sensor_cleaning && errors.sensor_cleaning)}
              helperText={touched.sensor_cleaning && errors.sensor_cleaning}
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"number"}
              label="sensor_cleaning_interval"
              {...getFieldProps("sensor_cleaning_interval")}
              error={Boolean(
                touched.sensor_cleaning_interval &&
                  errors.sensor_cleaning_interval
              )}
              helperText={
                touched.sensor_cleaning_interval &&
                errors.sensor_cleaning_interval
              }
              disabled={deviceRunning}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"number"}
              label="sensor_cleaning_time"
              {...getFieldProps("sensor_cleaning_time")}
              error={Boolean(
                touched.sensor_cleaning_time && errors.sensor_cleaning_time
              )}
              helperText={
                touched.sensor_cleaning_time && errors.sensor_cleaning_time
              }
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"number"}
              label="blockage_interval"
              {...getFieldProps("blockage_interval")}
              error={Boolean(
                touched.blockage_interval && errors.blockage_interval
              )}
              helperText={touched.blockage_interval && errors.blockage_interval}
              disabled={deviceRunning}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"string"}
              label="vibrator"
              {...getFieldProps("vibrator")}
              error={Boolean(touched.vibrator && errors.vibrator)}
              helperText={touched.vibrator && errors.vibrator}
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"string"}
              label="fluid_conveyor"
              {...getFieldProps("fluid_conveyor")}
              error={Boolean(touched.fluid_conveyor && errors.fluid_conveyor)}
              helperText={touched.fluid_conveyor && errors.fluid_conveyor}
              disabled={deviceRunning}
            />
          </Stack>

          <Stack direction={{ xs: "column", sm: "row" }} spacing={2}>
            <TextField
              fullWidth
              type={"string"}
              label="discharge_opt"
              {...getFieldProps("discharge_opt")}
              error={Boolean(touched.discharge_opt && errors.discharge_opt)}
              helperText={touched.discharge_opt && errors.discharge_opt}
              disabled={deviceRunning}
            />
            <TextField
              fullWidth
              type={"number"}
              label="vacuum_diff_level"
              {...getFieldProps("vacuum_diff_level")}
              error={Boolean(
                touched.vacuum_diff_level && errors.vacuum_diff_level
              )}
              helperText={touched.vacuum_diff_level && errors.vacuum_diff_level}
              disabled={deviceRunning}
            />
          </Stack>

          {!deviceRunning && (
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              loading={isSubmitting}
              color="success"
              variant="contained"
              startIcon={<Icon icon={playCircleFill} />}
              disabled={startDisabled}
            >
              Start test
            </LoadingButton>
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
};
export default RunTestForm;
