import ReactApexChart from "react-apexcharts";
// material
import { Box, Card, CardHeader } from "@mui/material";

const ValvePositionGraph: React.FC<{
  valvePositions: Map<number, number>;
}> = ({ valvePositions }) => {
  return (
    <Card>
      <CardHeader title="Valve Positions" />
      <Box sx={{ mx: 3 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={[
            {
              name: "Valve Pos",
              data: Array.from(valvePositions).map((value) => {
                return { x: value[0] / 1000, y: value[1] };
              }),
            },
          ]}
          options={{
            stroke: {
              curve: "stepline",
            },
            xaxis: {
              type: "numeric",
              title: { text: "Seconds" },
              min: 0,
              max: 10,
            },
            yaxis: {
              title: { text: "Valve position" },
            },
          }}
          height={200}
        />
      </Box>
    </Card>
  );
};

export default ValvePositionGraph;
