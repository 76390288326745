import React from "react";
import ReactApexChart from "react-apexcharts";
// material
import { Box, Card, CardHeader } from "@mui/material";

export type AverageRecordType = {
  id: string;
  recordTime: Date;
  p?: number;
  v?: number;
  vm?: number;
  diff?: string | null;
  pos?: number;
};

const AverageGraph: React.FC<{
  chartType: string;
  data: AverageRecordType[];
  height?: number;
  name: string;
  xAxisTitle: string;
  yAxisTitle: string;
}> = ({ chartType, data, height, name, xAxisTitle, yAxisTitle }) => {
  return (
    <Card>
      <CardHeader title={name} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <ReactApexChart
          type="line"
          series={[
            {
              name: name,
              data: data.map((value, index) => {
                return {
                  x: index + 1,
                  y: value[chartType],
                  recordTime: value["recordTime"].toLocaleString(),
                };
              }),
            },
          ]}
          options={{
            xaxis: {
              type: "numeric",
              title: { text: xAxisTitle },
              min: 1,
              max: data.length,
              tickAmount: "dataPoints",
            },
            yaxis: {
              title: { text: yAxisTitle },
              min: 0,
            },
            tooltip: {
              custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                const recordTime =
                  w.config?.series[seriesIndex]?.data[dataPointIndex]
                    ?.recordTime;

                return `<div style="padding:10px "> 
                  <div>
                  ${yAxisTitle}: ${series[seriesIndex][dataPointIndex]} 
                  </div>
                  <div>
                  ${recordTime}
                  </div>
                  </div>`;
              },
            },
          }}
          height={height ?? 200}
        />
      </Box>
    </Card>
  );
};

export default AverageGraph;
